import { ConductSelectTypesSelectedValue, FilterKeys } from '@schooly/api';
import { useEffect, useMemo } from 'react';

const LAST_APPLIED_FILTERS_STORAGE_KEY = 'lastAppliedFilters';

export enum StoredFilterSections {
  Students = 'students',
  Parents = 'parents',
  Staff = 'staff',
  Groups = 'groups',
  Messages = 'messages',
  Assessments = 'assessments',
  Reports = 'reports',
  Attendance = 'attendance',
  Conduct = 'conduct',
  Events = 'events',
  SignUps = 'signups',
}

interface StoredFiltersState {
  filters: Partial<Record<FilterKeys, any>>;
  arrangeBy?: FilterKeys | null;
  groupBy?: FilterKeys | null;
  showByPresentAbsent?: boolean;
  conductSelectTypes?: ConductSelectTypesSelectedValue[];
}

interface StoredFilters {
  schoolId: string;
  filtersState?: Partial<Record<StoredFilterSections, StoredFiltersState>>;
}

export const useLastAppliedFiltersState = <F extends string, A extends string, G extends string>({
  schoolId,
  type,
  filterKeys,
  arrangeByKeys,
  groupByKeys,
}: {
  schoolId: string;
  type: StoredFilterSections;
  filterKeys: readonly F[];
  arrangeByKeys?: readonly A[];
  groupByKeys?: readonly G[];
  showByPresentAbsent?: boolean;
}) => {
  const {
    lastAppliedFilter,
    lastAppliedArrangeBy,
    lastAppliedGroupBy,
    lastAppliedShowByPresentAbsent,
    lastAppliedConductTypeOptions,
  } = useMemo(() => {
    const storedFilters = sessionStorage.getItem(LAST_APPLIED_FILTERS_STORAGE_KEY);
    const currentFilters = storedFilters ? JSON.parse(storedFilters) : null;
    const filtersForCurrentSchool =
      currentFilters?.schoolId === schoolId && currentFilters?.filtersState
        ? currentFilters.filtersState
        : null;
    const filtersForType = type ? filtersForCurrentSchool?.[type] : null;

    let lastAppliedFilter;
    if (filtersForType && filtersForType.filters && filterKeys) {
      const filtersResult: Partial<Record<F, any>> = {};
      for (const key of filterKeys) {
        const param = filtersForType.filters[key];
        if (!param) continue;
        filtersResult[key] = param;
      }
      lastAppliedFilter = Object.keys(filtersResult).length ? filtersResult : null;
    }

    let lastAppliedArrangeBy;
    if (filtersForType && filtersForType.arrangeBy !== undefined && arrangeByKeys) {
      for (const key of arrangeByKeys) {
        if (key === filtersForType.arrangeBy) {
          lastAppliedArrangeBy = key;
        }
      }
      lastAppliedArrangeBy = lastAppliedArrangeBy ?? null;
    }

    let lastAppliedGroupBy;
    if (filtersForType && filtersForType.groupBy !== undefined && groupByKeys) {
      for (const key of groupByKeys) {
        if (key === filtersForType.groupBy) {
          lastAppliedGroupBy = key;
        }
      }
      lastAppliedGroupBy = lastAppliedGroupBy ?? null;
    }

    let lastAppliedConductTypeOptions;
    if (filtersForType && filtersForType.conduct_select_types !== undefined) {
      lastAppliedConductTypeOptions = filtersForType.conduct_select_types;
      lastAppliedConductTypeOptions = lastAppliedConductTypeOptions ?? null;
    }

    let lastAppliedShowByPresentAbsent;
    if (filtersForType && filtersForType.showByPresentAbsent !== undefined) {
      lastAppliedShowByPresentAbsent = filtersForType.showByPresentAbsent;
    }

    return {
      lastAppliedFilter,
      lastAppliedArrangeBy,
      lastAppliedGroupBy,
      lastAppliedShowByPresentAbsent,
      lastAppliedConductTypeOptions,
    };
  }, [arrangeByKeys, filterKeys, groupByKeys, schoolId, type]);

  return {
    lastAppliedFilter,
    lastAppliedArrangeBy,
    lastAppliedGroupBy,
    lastAppliedShowByPresentAbsent,
    lastAppliedConductTypeOptions,
  };
};

const storedFilters = sessionStorage.getItem(LAST_APPLIED_FILTERS_STORAGE_KEY);
let currentFilters: StoredFilters = storedFilters ? JSON.parse(storedFilters) : {};

export const useSaveLastAppliedFiltersState = ({
  schoolId,
  type,
  filters,
  arrangeBy,
  groupBy,
  showByPresentAbsent,
  conductSelectTypes,
}: {
  schoolId: string;
  type: StoredFilterSections;
  filters: Partial<Record<FilterKeys, any>>;
  arrangeBy?: FilterKeys | null;
  groupBy?: FilterKeys | null;
  conductSelectTypes?: ConductSelectTypesSelectedValue[] | null;
  showByPresentAbsent?: boolean;
}) => {
  useEffect(() => {
    if (!schoolId) return;

    if (!currentFilters?.schoolId || currentFilters?.schoolId !== schoolId) {
      currentFilters = { schoolId };
    }

    currentFilters.filtersState = {
      ...(currentFilters.filtersState ?? {}),
      [type]: {
        filters,
        arrangeBy,
        groupBy,
        showByPresentAbsent,
        conduct_select_types: conductSelectTypes,
      },
    };

    sessionStorage.setItem(LAST_APPLIED_FILTERS_STORAGE_KEY, JSON.stringify(currentFilters));
  }, [filters, arrangeBy, groupBy, showByPresentAbsent, type, schoolId, conductSelectTypes]);
};

export function clearLastAppliedFilter(type: StoredFilterSections) {
  const data = sessionStorage.getItem(LAST_APPLIED_FILTERS_STORAGE_KEY);
  if (!data) return;
  const storedFilters = JSON.parse(data);
  delete storedFilters?.filtersState?.[type];
  sessionStorage.setItem(LAST_APPLIED_FILTERS_STORAGE_KEY, JSON.stringify(storedFilters));
}
