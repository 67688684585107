import { IconButton, Stack, Typography } from '@mui/material';
import { ConductTypeSelectOption } from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { ArchiveIcon, DeleteIcon, DragIcon, RollBackIcon } from '@schooly/style';
import { FC } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ConductTypeForm } from './SchoolConductTypeModalContent';

export interface SelectRowProps {
  id: string;
  maxBodyLength?: number;
  provided?: DraggableProvided;
  option: ConductTypeSelectOption & { id: string; saved: boolean };
  optionList: (ConductTypeSelectOption & { id: string })[];
  validate?: (id: string, value: string) => string | boolean;
  onArchiveOption: (id: string) => void;
  onRestoreOption: (id: string) => void;
  onDeleteOption: (index: number) => void;
}

export const SchoolConductTypeSelectRow: FC<SelectRowProps> = ({
  id,
  maxBodyLength,
  provided,
  optionList,
  option,
  validate,
  onArchiveOption,
  onRestoreOption,
  onDeleteOption,
}) => {
  const { formatMessage } = useIntl();
  const { control, watch } = useFormContext<ConductTypeForm>();

  // const option = watch(`select_type_options.${rowIdx}`);
  const orignialList = watch('select_type_options');

  const bodyLength = option.label.length;
  const maxLengthError = !!bodyLength && !!maxBodyLength && bodyLength > maxBodyLength;
  const rowIdx = orignialList?.findIndex((opt) => opt.id === option.id) || 0;

  return (
    <>
      <Stack
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        direction="row"
        alignItems="baseline"
        gap={1}
        style={provided?.draggableProps.style}
      >
        {provided ? (
          <IconButton inverse {...provided.dragHandleProps}>
            <DragIcon />
          </IconButton>
        ) : (
          <IconButton sx={{ opacity: 0, cursor: 'none' }}>
            <DragIcon />
          </IconButton>
        )}

        <Stack sx={{ flex: '1 1 90%', position: 'relative', mb: 3 }}>
          <ControlTextField
            label={formatMessage({ id: 'option' })}
            name={`select_type_options.${rowIdx}.label`}
            required
            disabled={option.archived}
            error={maxLengthError}
            control={control}
            rules={{
              validate: (value) => {
                if (!value) {
                  return optionList?.length === 1
                    ? formatMessage({
                        id: 'school-sections-CustomFields-Message-OneOptionRequired',
                      })
                    : formatMessage({
                        id: 'input-ErrorRequired',
                      });
                }

                if (maxLengthError) {
                  return formatMessage({
                    id: 'school-sections-CustomFields-Message-CharacterLimit',
                  });
                }

                return validate ? validate(id, value) : true;
              },
            }}
            sx={() => ({
              '.MuiFormHelperText-root': {
                position: 'absolute',
                left: '0',
                bottom: '-20px',
              },
            })}
          />

          {bodyLength !== undefined && maxBodyLength !== undefined && (
            <Typography
              variant="caption"
              textAlign="right"
              color={maxLengthError ? 'error.main' : 'common.grey'}
              sx={{ position: 'absolute', right: '0', bottom: '-20px' }}
            >
              {bodyLength}
              {!!maxBodyLength && ` / ${maxBodyLength}`}
            </Typography>
          )}
        </Stack>

        {(Number(optionList?.length) > 1 || option.archived) && (
          <IconButton
            sx={{
              display: 'flex',
              visibility: 'visible',
              alignSelf: 'start',
              pt: 1.8,
            }}
            size="medium"
            inverse
            onClick={() => {
              !option.saved
                ? onDeleteOption(rowIdx)
                : option.archived
                ? onRestoreOption(option.id)
                : onArchiveOption(option.id);
            }}
          >
            {option.saved ? option.archived ? <RollBackIcon /> : <ArchiveIcon /> : <DeleteIcon />}
          </IconButton>
        )}
      </Stack>
    </>
  );
};
