import { Input, InputProps, Stack, useTheme } from '@mui/material';
import { BaseComment, Comment, ConductType, SimpleListResult } from '@schooly/api';
import { ConductEntryPatch, ConductTypeRepresentation, ConductVisibility } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DropdownIcon } from '@schooly/style';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import PersonCardBasic from '../../../components/common/PersonCard/PersonCardBasic';
import { Dropdown, DropdownOption } from '../../../components/uikit-components/Dropdown/Dropdown';
import { DropdownMenu } from '../../../components/uikit-components/Dropdown/Dropdown.styled';
import { DropdownComments } from '../../../components/uikit-components/DropdownCommentsV2/DropdownComments';
import { CommentRowCell } from '../../../components/uikit-components/DropdownCommentsV2/DropdownCommentsWrappers/CommentRowCell';
import {
  Grid,
  GridBody,
  GridCell,
  GridContainer,
  GridRow,
} from '../../../components/uikit-components/Grid/Grid';
import { useWithRef } from '../../../hooks/useWithRef';
import { commentToDropdownCommentItem } from '../../../utils/convertComments';
import { ConductVisibilityButton } from '../ConductVisibilityButton';

export interface ConductManageGridProps {
  selectedConductType?: ConductType;
  selectConductTypes?: ConductType[];
  selectedStudents: SimpleListResult[];
  conductEntryPatches: Record<string, ConductEntryPatch>;
  setConductEntryPatches: (v: Record<string, ConductEntryPatch>) => void;
  errors?: Record<string, boolean> | null;
  onChange?(studentRelationId?: string): void;
}

function GhostCellInput({ padding, ...props }: InputProps & { padding?: string }) {
  return (
    <Input
      disableUnderline
      fullWidth
      maxRows={10}
      sx={(theme) => ({
        padding: padding ?? theme.spacing(1),
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',

        '&.Mui-focused': {
          background: theme.palette.background.paper,
        },
      })}
      {...props}
    />
  );
}

export const ConductManageGrid: FC<ConductManageGridProps> = ({
  errors,
  onChange,
  selectedStudents,
  selectedConductType,
  selectConductTypes,
  setConductEntryPatches,
  conductEntryPatches,
}) => {
  const { currentStaff } = useAuth();
  const theme = useTheme();
  const { $t } = useIntl();

  const showNumber = selectedConductType?.type === ConductTypeRepresentation.NUMBER;
  const showText = selectedConductType?.type === ConductTypeRepresentation.TEXT;
  const showDropdown = selectedConductType?.type === ConductTypeRepresentation.SELECT;

  const dropdownOptions: DropdownOption<any>[] = (
    selectConductTypes?.find((type) => type.id === selectedConductType?.id)?.select_type_options ||
    []
  )
    .filter((option) => !option.archived)
    .map((option) => ({
      label: option.label,
      value: option.label,
    }));

  const { containerRef } = useWithRef();

  const setEntryComment = useCallback<
    (entryId: string, relationId?: string, comment?: string) => void
  >(
    async (studentId, relationId, comment = '') => {
      if (!currentStaff) {
        return;
      }

      const comments: BaseComment[] = [...(conductEntryPatches[studentId]?.comments ?? [])];

      const existingCommentIndex = relationId
        ? comments.findIndex((comment) => comment.creator_relation_id === relationId) ?? -1
        : -1;

      const existingComment =
        existingCommentIndex >= 0 ? comments[existingCommentIndex] : undefined;

      if (existingComment && existingComment.comment === comment) {
        return;
      }

      const tmpComment: BaseComment = existingComment
        ? { ...existingComment, comment }
        : {
            comment,
            creator_relation_id: currentStaff.relation_id,
            creator_title: currentStaff.title,
            creator_given_name: currentStaff.given_name,
            creator_last_name: currentStaff.last_name,
            creator_known_as: currentStaff.known_as,
          };

      if (existingCommentIndex >= 0) {
        comments[existingCommentIndex]! = tmpComment;
      } else {
        comments.push(tmpComment);
      }

      setConductEntryPatches({
        ...conductEntryPatches,
        [studentId]: { ...conductEntryPatches[studentId], comments: comments as Comment[] },
      });
    },
    [setConductEntryPatches, conductEntryPatches, currentStaff],
  );

  const handleCommentAdd = useCallback(
    (studentId: string) => (comment: string) => {
      if (comment) {
        setEntryComment(studentId, undefined, comment);
      }
    },
    [setEntryComment],
  );

  const handleCommentEdit = useCallback(
    (studentId: string) => (comment: string, relationId: string) => {
      setEntryComment(studentId, relationId, comment);
    },
    [setEntryComment],
  );

  const handleConductVisibility = useCallback(
    (studentId: string, currentVisibility: ConductVisibility) => {
      const visibility =
        currentVisibility === ConductVisibility.NOT_PUBLISHED
          ? ConductVisibility.PUBLISHED
          : ConductVisibility.NOT_PUBLISHED;

      setConductEntryPatches({
        ...conductEntryPatches,
        [studentId]: { ...conductEntryPatches[studentId], visibility },
      });
    },
    [setConductEntryPatches, conductEntryPatches],
  );

  const updateHandler = useCallback(
    (relationIdToUpdate: string, value: Partial<ConductEntryPatch>) => {
      const updated = {
        ...conductEntryPatches,
        [relationIdToUpdate]: {
          relation_id: relationIdToUpdate,
          ...conductEntryPatches[relationIdToUpdate],
          ...value,
        },
      };

      setConductEntryPatches(updated);
      onChange?.(relationIdToUpdate);
    },
    [setConductEntryPatches, conductEntryPatches, onChange],
  );

  return (
    <GridContainer sx={{ px: 2.5 }}>
      <Grid
        sx={{
          height: 1, // hack to take cell's child full height (GhostCellInput)
          borderCollapse: 'collapse',
        }}
      >
        <GridBody>
          {selectedStudents.map((student, i) => {
            const conductEntryPatch = conductEntryPatches[student.relation_id];

            const comments = conductEntryPatch?.comments?.map(commentToDropdownCommentItem) ?? [];
            const visibility = conductEntryPatch?.visibility ?? ConductVisibility.PUBLISHED;
            const showBorderTop = i === 0;
            const showBorderBottom = i === selectedStudents.length - 1;

            return (
              <GridRow
                key={student.user_id}
                error={errors?.[student.relation_id]}
                sx={{
                  '&:hover': {
                    // TODO: textarea::placeholder is not activated
                    '.ConductManageGrid__cell__detail textarea::placeholder, .UIKit-DropdownComments__icon, .UIKit-Dropdown__icon':
                      {
                        color: (theme) => theme.palette.common.grey2,
                        opacity: 1,
                      },
                    '.UIKit-Dropdown input': {
                      color: (theme) =>
                        conductEntryPatch?.title
                          ? theme.palette.primary.main
                          : theme.palette.common.grey2,
                      opacity: 1,
                    },
                  },
                  '.UIKit-Dropdown input': {
                    color: (theme) =>
                      conductEntryPatch?.title ? theme.palette.common.grey2 : 'undefined',
                  },
                }}
              >
                <GridCell
                  borderTop={showBorderTop}
                  borderBottom={showBorderBottom}
                  borderRight
                  sx={{ verticalAlign: 'top' }}
                  width={44}
                >
                  <ConductVisibilityButton
                    onClick={() => handleConductVisibility(student.relation_id, visibility)}
                    tooltipTitle={
                      visibility === ConductVisibility.NOT_PUBLISHED
                        ? $t({ id: 'conduct-stuff-visible-create' })
                        : $t({ id: 'conduct-parents-visible-create' })
                    }
                    selected={visibility === ConductVisibility.PUBLISHED}
                    width={200}
                  />
                </GridCell>
                <GridCell
                  noVerticalPadding
                  borderRight
                  borderTop={showBorderTop}
                  borderBottom={showBorderBottom}
                  width={200}
                  sx={{
                    maxWidth: 400,
                    verticalAlign: 'top',
                    '&&': {
                      pt: '3px',
                    },
                  }}
                >
                  <PersonCardBasic userType="student" user={student} isListItem />
                </GridCell>

                {/* Value / Title */}
                <GridCell
                  noPadding
                  borderRight
                  borderTop={showBorderTop}
                  borderBottom={showBorderBottom}
                  width={160}
                  sx={{
                    verticalAlign: 'top',
                    '.UIKit-Dropdown': {
                      pt: 1,

                      '& input': {
                        fontSize: 12,
                        width: `${conductEntryPatch?.title?.length ?? 5}ch`,
                      },

                      '& .UIKit-Dropdown__port': {
                        gap: 0,
                      },
                    },
                  }}
                >
                  {showDropdown && (
                    <Dropdown
                      placeholder="Title"
                      options={dropdownOptions}
                      value={conductEntryPatch?.title}
                      onChange={(option) => {
                        updateHandler(student.relation_id, { title: option?.value, value: null });
                      }}
                      PaperProps={{
                        ...DropdownMenu.defaultProps?.PaperProps,
                        sx: { width: 176 },
                      }}
                      transformOrigin={{ vertical: -8, horizontal: 8 }}
                      icon={<DropdownIcon />}
                      iconPlacement="end"
                    />
                  )}

                  {showText && (
                    <GhostCellInput
                      type="text"
                      padding={theme.spacing(1.5, 1)}
                      placeholder={$t({ id: 'conduct-Type-Title' })}
                      value={conductEntryPatch?.title}
                      onChange={(e) =>
                        updateHandler(student.relation_id, { title: e.target.value, value: null })
                      }
                    />
                  )}

                  {showNumber && (
                    <GhostCellInput
                      type="number"
                      padding={theme.spacing(1.5, 1)}
                      inputProps={{ min: 0 }}
                      placeholder={$t({ id: 'number' })}
                      value={conductEntryPatch?.value}
                      onChange={(e) =>
                        updateHandler(student.relation_id, { title: null, value: e.target.value })
                      }
                    />
                  )}
                </GridCell>

                <GridCell
                  noPadding
                  borderRight
                  borderTop={showBorderTop}
                  borderBottom={showBorderBottom}
                  width={500}
                >
                  <GhostCellInput
                    placeholder="Detail"
                    multiline
                    padding="17px 8px"
                    className="ConductManageGrid__cell__detail"
                    value={conductEntryPatch?.details}
                    onChange={(e) =>
                      updateHandler(student.relation_id, { details: e.target.value })
                    }
                  />
                </GridCell>

                <GridCell
                  noVerticalPadding
                  borderTop={showBorderTop}
                  borderBottom={showBorderBottom}
                  sx={{ verticalAlign: 'top' }}
                >
                  <Stack py={1} justifyContent="center" alignItems="center">
                    <CommentRowCell>
                      {(onToggle, cellRef) => (
                        <DropdownComments
                          comments={comments}
                          onAdd={handleCommentAdd(student.relation_id)}
                          onEdit={handleCommentEdit(student.relation_id)}
                          canAdd
                          canEditOwn
                          getParentRef={() => cellRef}
                          onToggle={onToggle}
                          popoverMargin={2}
                          ref={containerRef}
                        />
                      )}
                    </CommentRowCell>
                  </Stack>
                </GridCell>
              </GridRow>
            );
          })}
        </GridBody>
      </Grid>
    </GridContainer>
  );
};
