import {
  Application,
  ApplicationChild,
  ApplicationCreateCustomField,
  ApplicationCreateRequest,
  ApplicationCustomField,
  ApplicationEnrollment,
  ApplicationStatus,
  convertApplication,
  createApplication as createApplicationRequest,
  CustomFieldDataType,
  getApplication,
  rejectApplication,
  updateApplicationNotes,
  useGetApplicationsQuery,
} from '@schooly/api';
import { ApiError } from '@schooly/api';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import {
  convertChildFormToApplicationChild,
  convertCustomFieldsToApplicationCustomFields,
} from '@schooly/utils/application-helpers';
import { convertDataTypeToDefaultValue } from '@schooly/utils/application-helpers';
import { isNotEmpty, propsAreNotEmpty } from '@schooly/utils/predicates';
import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetching } from '../../hooks/useFetching';
import { useSchool } from '../../hooks/useSchool';
import useUserCounts from '../../hooks/useUserCounts';
import { ApplicationAccessLayer } from '../../pages/Applications/ApplicationAccessLayer';
import {
  ApplicationContext,
  ApplicationContextProps,
  ApplicationsTab,
  ApplyStudentsForm,
  CreateApplicationProps,
} from './ApplicationsContext';

const normalizeCustomFieldsToApi = (customFields: ApplicationCustomField[]) => {
  return customFields.reduce<ApplicationCreateCustomField[]>(
    (acc, { value, custom_field_id, is_severe, data_type }) => {
      const isSelect = data_type === CustomFieldDataType.SELECT;
      const isEmpty = value === '' || value === undefined || value === null;

      if (isEmpty) {
        return [
          ...acc,
          {
            custom_field_id,
            custom_field_value: convertDataTypeToDefaultValue(data_type),
            custom_field_value_severe: is_severe,
          },
        ];
      }

      return [
        ...acc,
        {
          custom_field_id,
          custom_field_value: isSelect ? [value] : value,
          custom_field_value_severe: is_severe,
        },
      ];
    },
    [],
  );
};

const normalizeChildFormsToApi = (children: CreateChildForm[]) => {
  return children.map((c) => {
    const { custom_fields_values, ...child } = convertChildFormToApplicationChild(c);

    const customFields = normalizeCustomFieldsToApi(custom_fields_values ?? []);

    return removeObjectUndefinedOrNullValues({
      ...child,
      custom_fields: customFields?.length ? customFields : undefined,
    });
  });
};

const normalizeParentFormsToApi = (parents: CreateParentForm[]) => {
  return parents.map((p, i) => {
    const { phoneCode, telephone, profession, custom_fields, ...rest } = p;
    const phoneNumber = phoneCode ? `${phoneCode}${telephone}` : telephone;

    const applicationCustomFields = convertCustomFieldsToApplicationCustomFields(custom_fields);
    const customFields = normalizeCustomFieldsToApi(applicationCustomFields);

    return removeObjectUndefinedOrNullValues({
      ...rest,
      telephone: phoneNumber || undefined,
      profession: profession || undefined,
      custom_fields: customFields?.length ? customFields : undefined,
    });
  });
};

const selectedApplicationTabParams: { [key in ApplicationsTab]: ApplicationStatus } = {
  [ApplicationsTab.Open]: 'open',
  [ApplicationsTab.Converted]: 'converted',
  [ApplicationsTab.Rejected]: 'rejected',
};

export const WithApplication: FC<PropsWithChildren> = ({ children }) => {
  const [application, setApplication] = useState<Application>();
  const { id } = useParams<'id'>();
  const [activeTab, setActiveTab] = useState<ApplicationsTab>(ApplicationsTab.Open);
  const { updateCounts } = useUserCounts();
  const invalidateQueries = useInvalidateListQueriesFor('student');
  const { schoolId } = useSchool();
  const { showNotification } = useNotifications();
  const {
    data: applicationList,
    refetch: fetchApplicationList,
    isLoading: isApplicationsLoading,
  } = useGetApplicationsQuery(
    {
      school_id: schoolId!,
      status: selectedApplicationTabParams[activeTab],
    },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const [fetching, setFetching] = useState(false);
  const { showError } = useNotifications();

  const { fetching: updating, setFetching: setUpdating } = useFetching();

  const { permissions } = useAuth();

  const isApplicationManager = permissions.includes('application_manager');
  const canView = permissions.includes('application_viewer');
  const canEdit = isApplicationManager && application?.creator_type === SchoolUserRole.Staff;

  const showSuccessConvertNotification = useCallback(
    (children: ApplyStudentsForm['children']) => {
      let notificationTextId = 'applications-SuccessfulCovert-AddingStudents';
      let createdStudentCount = children.length;
      let updatedStudentCount = 0;

      const studentsUpdated = children.filter((child) => child.relationId);
      if (studentsUpdated.length) {
        updatedStudentCount = studentsUpdated.length;
        notificationTextId =
          studentsUpdated.length > 1
            ? 'applications-SuccessfulCovert-UpdatingStudents-plural'
            : 'applications-SuccessfulCovert-UpdatingStudents-singular';

        if (children.length - studentsUpdated.length) {
          createdStudentCount = children.length - studentsUpdated.length;
          notificationTextId =
            studentsUpdated.length > 1
              ? 'applications-SuccessfulCovert-AddingAndUpdatingStudents-plural'
              : 'applications-SuccessfulCovert-AddingAndUpdatingStudents-singular';
        }
      }

      showNotification({
        textId: notificationTextId,
        values: { updatedStudentCount, createdStudentCount },
        type: 'success',
      });
    },
    [showNotification],
  );

  const handleApplyStudents = useCallback(
    async (data: ApplyStudentsForm) => {
      if (!id) {
        return;
      }
      setFetching(true);

      const applicationEnrollments: ApplicationEnrollment[] = data.children.map((child) => {
        const { age_group_property_id, house_property_id, school_year_id } = child;
        const schoolPropertiesIds =
          [age_group_property_id, house_property_id].filter(isNotEmpty) ?? [];

        const enrollmentStatus = child.statuses
          .map(({ applies_to, ...rest }) => rest)
          .filter(propsAreNotEmpty);

        return removeObjectUndefinedOrNullValues({
          application_child_id: child.childId,
          relation_id: child.relationId,
          school_year_id: school_year_id,
          school_property_ids: schoolPropertiesIds.filter(Boolean),
          statuses: enrollmentStatus.length ? enrollmentStatus : undefined,
          half_day: child.half_day,
        });
      });

      try {
        await convertApplication({
          id,
          enrollments: applicationEnrollments,
        });
        invalidateQueries();
        showSuccessConvertNotification(data.children);
        return id;
      } catch (error) {
        showError(error as ApiError);
      } finally {
        setFetching(false);
      }
    },
    [id, invalidateQueries, showError, showSuccessConvertNotification],
  );

  const createApplication = useCallback(
    async ({ children, parents, generalCustomFields }: CreateApplicationProps) => {
      if (!schoolId) {
        return;
      }

      if (activeTab === ApplicationsTab.Rejected) {
        setActiveTab(ApplicationsTab.Open);
      }

      try {
        setFetching(true);

        const requestChildren: ApplicationCreateRequest['children'] = normalizeChildFormsToApi(
          children,
        ).map(({ id, ...rest }) => ({ ...rest, relation_id: id }));

        const requestParents: ApplicationCreateRequest['adults'] = normalizeParentFormsToApi(
          parents,
        ).map(({ can_be_edited, id, relationship_type, ...rest }, i) => ({
          ...rest,
          relation_id: id,
        }));

        const applicationGeneralCustomFields =
          convertCustomFieldsToApplicationCustomFields(generalCustomFields);
        const requestCustomFields = normalizeCustomFieldsToApi(applicationGeneralCustomFields);

        const relations = normalizeParentFormsToApi(parents).reduce<
          ApplicationCreateRequest['relations']
        >((acc, { relationship_type, id: adult_id }) => {
          return [
            ...(acc ?? []),
            ...requestChildren.map(({ relation_id }) => ({
              child_rid: relation_id!,
              adult_rid: adult_id,
              relationship_type: relationship_type ?? null,
            })),
          ];
        }, []);

        const response = await createApplicationRequest({
          schoolId,
          children: requestChildren,
          adults: requestParents,
          general_info_custom_fields_values: requestCustomFields,
          relations,
        });

        updateCounts();
        invalidateQueries();
        return response.application_id;
      } catch (error) {
        showError(error as ApiError);
      } finally {
        setFetching(false);
      }
    },
    [activeTab, invalidateQueries, schoolId, showError, updateCounts, setFetching],
  );

  const fetchApplication = useCallback(async () => {
    if (!id || !canView || !schoolId) {
      return;
    }

    setFetching(true);

    try {
      const response = await getApplication(id);
      setApplication(response);
    } catch (error) {
      showError(error as ApiError);
    } finally {
      setFetching(false);
    }
  }, [canView, id, schoolId, showError]);

  const reject = useCallback<ApplicationContextProps['rejectApplication']>(
    async (data) => {
      if (!id) {
        return;
      }

      if (activeTab === ApplicationsTab.Open) {
        setActiveTab(ApplicationsTab.Rejected);
      }

      try {
        setFetching(true);

        await rejectApplication({ id, ...removeObjectUndefinedOrNullValues(data) });
        invalidateQueries();
        updateCounts();
        return id;
      } catch (error) {
        showError(error as ApiError);
      } finally {
        setFetching(false);
      }
    },
    [activeTab, id, invalidateQueries, showError, updateCounts],
  );

  const updateApplication = useCallback(
    async ({ children, parents, generalCustomFields }: CreateApplicationProps) => {
      if (!application) {
        return;
      }
      setFetching(true);

      if (activeTab === ApplicationsTab.Rejected) {
        setActiveTab(ApplicationsTab.Open);
      }

      let isSuccessResponse = true;

      try {
        const childArr = normalizeChildFormsToApi(children).map((child) => {
          const existedChild = application.children.some((c) => c.id === child.id);

          return removeObjectUndefinedOrNullValues({
            ...child,
            id: existedChild ? child.id : undefined,
            relation_id: existedChild ? undefined : child.id,
          });
        });

        const parentArr = normalizeParentFormsToApi(parents).map((parent) => {
          const existedParent = application.adults.some((p) => p.id === parent.id);
          return removeObjectUndefinedOrNullValues({
            ...parent,
            id: existedParent ? parent.id : undefined,
            relation_id: existedParent ? undefined : parent.id,
          });
        });

        const applicationData: Application = {
          ...application,
          adults: parentArr.map((p) => {
            const existedParent = parents.find((p1) => p1.id === p.id);
            return {
              ...p,
              custom_fields: convertCustomFieldsToApplicationCustomFields(
                existedParent?.custom_fields ?? [],
              ),
            };
          }),
          children: childArr,
        };

        invalidateQueries();
        setApplication(applicationData);
      } catch (error) {
        isSuccessResponse = false;
        showError(error as ApiError);
      } finally {
        setFetching(false);
      }

      return isSuccessResponse;
    },
    [activeTab, application, invalidateQueries, showError],
  );

  const updateApplicationChildNotes = useCallback(
    async (updatedChild: ApplicationChild) => {
      if (!id || !application) {
        return;
      }
      setUpdating(updatedChild.id, true);

      try {
        const children = application.children.map((c) =>
          c.id === updatedChild.id ? updatedChild : c,
        );

        await updateApplicationNotes({
          id: application.id,
          children: children.map(({ id, notes }) => ({ id, notes })),
        });

        const applicationData: Application = {
          ...application,
          children,
        };

        setApplication(applicationData);
        return updatedChild.id;
      } catch (error) {
        showError(error as ApiError);
      } finally {
        setUpdating(updatedChild.id, false);
      }
    },
    [application, id, setUpdating, showError],
  );

  useEffect(() => {
    fetchApplication();
  }, [fetchApplication]);

  const value = {
    application,
    fetching: fetching || isApplicationsLoading,
    applicationId: id,
    handleApplyStudents,
    canEdit,
    canView,
    updateApplicationChildNotes,
    updating,
    rejectApplication: reject,
    isApplicationManager,
    activeTab,
    applicationList,
    setActiveTab,
    createApplication,
    updateApplication,
    fetchApplicationList,
  };

  return (
    <ApplicationContext.Provider value={value}>
      <ApplicationAccessLayer>{children}</ApplicationAccessLayer>
    </ApplicationContext.Provider>
  );
};
