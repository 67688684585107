import { Button, Stack } from '@mui/material';
import { PlusIcon } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { DndListWrapper } from '../../../components/uikit/Dnd/dnd.styled';
import { SELECT_LABEL_MAX_BODY_LENGTH } from '../SchoolCustomFields/constants';
import { ConductTypeForm } from './SchoolConductTypeModalContent';
import { SchoolConductTypeSelectRow } from './SchoolConductTypeSelectRow';

export const SchoolConductTypeSelect: FC = () => {
  const { formatMessage } = useIntl();
  const { control, watch } = useFormContext<ConductTypeForm>();
  const selectTypeOptions = watch('select_type_options');

  const { move, update, remove, insert } = useFieldArray({
    control: control,
    name: 'select_type_options',
  });

  const activeSelectOptions = useMemo(() => {
    return selectTypeOptions?.filter((option) => !option.archived);
  }, [selectTypeOptions]);

  const archivedSelectOptions = useMemo(() => {
    return selectTypeOptions?.filter((option) => option.archived);
  }, [selectTypeOptions]);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const handleAddSelectOption = useCallback(() => {
    insert(activeSelectOptions?.length || 0, {
      id: uuidv4(),
      order: activeSelectOptions?.length ?? 0,
      original_order: selectTypeOptions?.length ?? 0,
      label: '',
      archived: false,
      saved: false,
    });
  }, [activeSelectOptions, selectTypeOptions, insert]);

  const handleRemoveSelectOption = useCallback(
    (id: string) => {
      const option = selectTypeOptions?.find((option) => option.id === id);
      const optionIdx = selectTypeOptions?.findIndex((option) => option.id === id);

      if (!option) return;

      update(optionIdx, {
        ...option,
        archived: true,
      });

      move(optionIdx, selectTypeOptions.length - 1);
    },
    [selectTypeOptions, update, move],
  );

  const handleRestoreSelectOption = useCallback(
    (id: string) => {
      const option = selectTypeOptions?.find((option) => option.id === id);
      const optionIdx = selectTypeOptions?.findIndex((option) => option.id === id);

      if (!option) return;

      update(optionIdx, {
        ...option,
        order: activeSelectOptions.length,
        archived: false,
      });

      move(optionIdx, activeSelectOptions.length);
    },
    [selectTypeOptions, activeSelectOptions, update, move],
  );

  const handleDeleteSelectOption = useCallback(
    (id: number) => {
      remove(id);
    },
    [remove],
  );

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="ConductTypeOptionsDroppable">
          {(provided) => (
            <DndListWrapper
              {...provided.droppableProps}
              ref={provided.innerRef}
              gap={1}
              sx={{ overflowX: 'hidden' }}
            >
              {activeSelectOptions.map((option, index) => (
                <Draggable key={option.id} draggableId={option?.id ?? ''} index={index}>
                  {(provided) => (
                    <SchoolConductTypeSelectRow
                      id={option.id ?? ''}
                      provided={provided}
                      key={option.id}
                      maxBodyLength={SELECT_LABEL_MAX_BODY_LENGTH}
                      onArchiveOption={handleRemoveSelectOption}
                      onRestoreOption={handleRestoreSelectOption}
                      onDeleteOption={handleDeleteSelectOption}
                      option={option}
                      optionList={activeSelectOptions}
                      validate={(id, value) => {
                        const duplicates = selectTypeOptions.filter(
                          (option) => option.id !== id && option.label === value,
                        );

                        if (duplicates.length) {
                          return formatMessage({
                            id: 'school-sections-CustomFields-Message-ShouldBeUnique',
                          });
                        }

                        return true;
                      }}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}

              <Button
                variant="outlined"
                startIcon={<PlusIcon />}
                onClick={handleAddSelectOption}
                sx={{
                  '&.Mui-disabled': {
                    backgroundColor: (theme) => theme.palette.background.default,
                  },
                  width: '133px',
                }}
              >
                <FormattedMessage id="action-AddOption" />
              </Button>
            </DndListWrapper>
          )}
        </Droppable>
      </DragDropContext>

      <Stack direction="column" gap={1} mt={4}>
        {archivedSelectOptions.map((option, index) => (
          <SchoolConductTypeSelectRow
            id={option.id ?? ''}
            key={option.id}
            maxBodyLength={SELECT_LABEL_MAX_BODY_LENGTH}
            onArchiveOption={handleRemoveSelectOption}
            onRestoreOption={handleRestoreSelectOption}
            onDeleteOption={handleDeleteSelectOption}
            option={option}
            optionList={activeSelectOptions}
            validate={(id, value) => {
              const duplicates = selectTypeOptions.filter(
                (option) => option.id !== id && option.label === value,
              );

              if (duplicates.length) {
                return formatMessage({
                  id: 'school-sections-CustomFields-Message-ShouldBeUnique',
                });
              }

              return true;
            }}
          />
        ))}
      </Stack>
    </>
  );
};
