import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Icon,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { FilterKeys, SelectOption } from '@schooly/api';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { CheckIcon, InformationIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

export enum MoreButtonOptionType {
  Checkbox,
  Switch,
}

export interface MoreButtonOption<T> extends SelectOption<T> {
  type?: MoreButtonOptionType;
  disabled?: boolean;
  disabledHint?: React.ReactNode;
  required?: boolean;
  visible?: boolean;
}

type ToggleOption = {
  onToggle: () => void;
  selected: boolean;
  label: ReactNode;
  key: string;
};

export type MoreButtonProps<T, K> = {
  options: MoreButtonOption<T>[];
  selectedOptions: T[];
  onToggleOption: (v: T) => void;
  onClearFilters?: () => void;
  isSelectedArrangeBy?: boolean;
  onToggleArrangeBy?: () => void;
  groupBy?: K;
  isSelectedGroupBy?: boolean;
  onToggleGroupBy?: () => void;
  onResetToDefault?: () => void;
  title?: string;
  isSelectedShowByPresentAbsent?: boolean;
  onToggleShowByPresentAbsent?: () => void;
  isSelectedConductTypeOptions?: boolean;
  onToggleConductTypeOptions?: () => void;
  toggleOptions?: ToggleOption[];
};

export type MoreButton = {
  open: () => void;
};

const MoreButtonComponent = <T extends FilterKeys, K extends FilterKeys>(
  {
    options,
    selectedOptions,
    isSelectedArrangeBy,
    groupBy,
    isSelectedGroupBy,
    onToggleGroupBy,
    onToggleOption,
    onClearFilters,
    onResetToDefault,
    onToggleArrangeBy,
    isSelectedShowByPresentAbsent,
    onToggleShowByPresentAbsent,
    title,
    toggleOptions,
    isSelectedConductTypeOptions,
    onToggleConductTypeOptions,
  }: MoreButtonProps<T, K>,
  ref: React.ForwardedRef<MoreButton>,
) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [opened, open, close] = useFlag();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open],
  );

  const handleResetToDefault = useCallback(() => {
    onResetToDefault?.();
    close();
  }, [close, onResetToDefault]);

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 300,
                  maxWidth: 300,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Stack px={2.5} py={2.5} data-test-id={'filters-more-options'}>
                <Typography variant="h4" color="common.grey" mb={0.5}>
                  {title ?? <FormattedMessage id="filter-Filters" />}
                </Typography>
                {options.map((o) => {
                  if ('visible' in o && !o.visible) return null;
                  if (o.required) {
                    return (
                      <Stack
                        direction="row"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        gap={1}
                        py={1}
                      >
                        <Icon sx={{ display: 'flex' }}>
                          <CheckIcon />
                        </Icon>
                        <Typography variant="h3">{o.label}</Typography>
                      </Stack>
                    );
                  }
                  return (
                    <FormControlLabel
                      key={o.label}
                      disabled={o.disabled}
                      sx={o.type === MoreButtonOptionType.Switch ? { py: 1, pl: 1 } : undefined}
                      control={(() => {
                        switch (o.type) {
                          case MoreButtonOptionType.Switch:
                            return (
                              <Switch
                                checked={selectedOptions.includes(o.value)}
                                onChange={() => onToggleOption(o.value)}
                              />
                            );
                          case MoreButtonOptionType.Checkbox:
                          default:
                            return (
                              <Checkbox
                                checked={selectedOptions.includes(o.value)}
                                onChange={() => onToggleOption(o.value)}
                                icon={
                                  o.disabled && o.disabledHint ? (
                                    <Tooltip title={o.disabledHint}>
                                      <Box
                                        sx={{
                                          pointerEvents: 'all',
                                          '&:hover': { color: 'primary.main' },
                                        }}
                                      >
                                        <InformationIcon />
                                      </Box>
                                    </Tooltip>
                                  ) : undefined
                                }
                              />
                            );
                        }
                      })()}
                      label={o.label}
                    />
                  );
                })}
                {(onToggleArrangeBy ||
                  onToggleGroupBy ||
                  onToggleShowByPresentAbsent ||
                  onToggleConductTypeOptions ||
                  toggleOptions) && (
                  <>
                    {onToggleConductTypeOptions && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelectedConductTypeOptions}
                            onChange={onToggleConductTypeOptions}
                          />
                        }
                        label={
                          <Typography
                            variant="h3"
                            color={isSelectedConductTypeOptions ? undefined : 'common.grey'}
                          >
                            <FormattedMessage id="conduct-TypeOptions" />
                          </Typography>
                        }
                      />
                    )}

                    <Divider sx={{ my: 1 }} />

                    {onToggleArrangeBy && (
                      <FormControlLabel
                        control={
                          <Checkbox checked={isSelectedArrangeBy} onChange={onToggleArrangeBy} />
                        }
                        label={<FormattedMessage id="filter-ArrangeBy" />}
                      />
                    )}
                    {onToggleGroupBy && groupBy && (
                      <FormControlLabel
                        sx={{ ml: 0, mt: 1 }}
                        control={<Switch checked={isSelectedGroupBy} onChange={onToggleGroupBy} />}
                        label={
                          <Typography
                            variant="h3"
                            color={isSelectedGroupBy ? undefined : 'common.grey'}
                          >
                            <FormattedMessage id="filter-GroupBy" />{' '}
                            <FormattedMessage id={PROPERTIES_TEXT_IDS[groupBy]} />
                          </Typography>
                        }
                      />
                    )}
                    {onToggleShowByPresentAbsent && (
                      <FormControlLabel
                        sx={{ ml: 0, mt: 1 }}
                        control={
                          <Switch
                            checked={isSelectedShowByPresentAbsent}
                            onChange={onToggleShowByPresentAbsent}
                          />
                        }
                        label={
                          <Typography
                            variant="h3"
                            color={isSelectedGroupBy ? undefined : 'common.grey'}
                          >
                            <FormattedMessage id="filter-ShowByPresentAbsent" />{' '}
                          </Typography>
                        }
                      />
                    )}

                    {toggleOptions?.map(({ onToggle, selected, key, ...props }) => (
                      <FormControlLabel
                        sx={{ ml: 0, mt: 1 }}
                        key={key}
                        control={<Switch checked={selected} onChange={onToggle} />}
                        {...props}
                      />
                    ))}
                  </>
                )}
                {(onClearFilters || onResetToDefault) && (
                  <Stack flexDirection="row" justifyContent="space-between" mt={2}>
                    {onResetToDefault && (
                      <Button size="small" onClick={handleResetToDefault}>
                        <FormattedMessage id="filter-ResetToDefault" />
                      </Button>
                    )}
                    {onClearFilters && (
                      <Button variant="outlined" size="small" onClick={onClearFilters}>
                        <FormattedMessage id="filter-Clear" />
                      </Button>
                    )}
                  </Stack>
                )}
              </Stack>
            }
          >
            <Box ref={anchorRef}>
              <SimpleButton
                startIcon={<PlusIcon />}
                onClick={opened ? close : open}
                data-test-id={'filters-more'}
              >
                <FormattedMessage id="action-More" />
              </SimpleButton>
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MoreButton = forwardRef(MoreButtonComponent);
