const forceReactInputOnChange = (input: HTMLInputElement | HTMLTextAreaElement) => {
  if ((input as any)._valueTracker) {
    (input as any)._valueTracker.setValue('');
    input.dispatchEvent(
      new Event('input', {
        bubbles: true,
      }),
    );
  }
};

interface OpenJiraWidgetForRequestParams {
  summary?: string;
  description?: string;
  email?: string;
}

/**
 * Opens JSD widget with pre-populated data. As the JSD doesn't provide any API out of the box,
 * will be making dirty hacks to simulate the widget's flow.
 */

export async function openJiraWidgetForRequest(
  // searchRequest must contain request name
  searchRequest: string,
  { summary, description, email }: OpenJiraWidgetForRequestParams,
) {
  const widgetDoc = await performWithTimeout(
    () => (document.getElementById('jsd-widget') as HTMLIFrameElement | undefined)?.contentDocument,
    1000,
  );

  if (!widgetDoc) return;

  const button = await performWithTimeout(() => widgetDoc.getElementById('help-button'), 1000);

  if (button) {
    button.click();
  }

  const searchContainer = await performWithTimeout(() =>
    widgetDoc.getElementById('search-container'),
  );

  if (searchContainer) {
    // temporary hide search container
    searchContainer.style.opacity = '0';

    const input = (await performWithTimeout(() =>
      searchContainer.querySelector('[data-ds--text-field--input]'),
    )) as HTMLInputElement | null;
    const searchButton = (await performWithTimeout(() =>
      searchContainer.querySelector('button[type=submit]'),
    )) as HTMLButtonElement | null;

    if (!input || !searchButton) return;

    input.value = searchRequest;
    forceReactInputOnChange(input);

    searchButton.click();

    const requestListEl = (await performWithTimeout(
      () => {
        // Ensure that the right request is chosen
        const el = searchContainer.querySelectorAll('.search-results ul p.name');
        return Array.from(el).find((e) =>
          e.textContent?.toLowerCase().includes(searchRequest.toLowerCase()),
        );
      },

      1000,
    )) as HTMLElement | null;

    if (!requestListEl) return;

    requestListEl.click();
  }

  const summaryInput = (await performWithTimeout(() =>
    widgetDoc.querySelector('.form-container input#summary'),
  )) as HTMLInputElement | null;

  const descriptionInput = (await performWithTimeout(() =>
    widgetDoc.querySelector('.form-container textarea#description'),
  )) as HTMLTextAreaElement | null;

  const emailInput = (await performWithTimeout(() =>
    widgetDoc.querySelector('.form-container input#email'),
  )) as HTMLInputElement | null;

  if (summaryInput) {
    summaryInput.value = summary || '';
    forceReactInputOnChange(summaryInput);
  }

  if (descriptionInput) {
    descriptionInput.value = description ?? '';
    forceReactInputOnChange(descriptionInput);
  }

  if (emailInput) {
    emailInput.value = email ?? '';
    forceReactInputOnChange(emailInput);
  }
}

const performWithTimeout = async <T extends unknown>(
  request: () => T,
  maxTimeout = 100,
  retry = 0,
): Promise<T | null> => {
  const nextTimeout = retry * 100;

  if (nextTimeout > maxTimeout) return null;

  const el = request();

  if (!el) {
    await awaitTimeout(nextTimeout);
    return await performWithTimeout(request, maxTimeout, retry + 1);
  }

  return el;
};

const awaitTimeout = (v = 0) =>
  new Promise((res) => {
    if (!v) {
      res(true);
      return;
    }

    setTimeout(res, v);
  });
