import { Stack, Typography } from '@mui/material';
import { ConductConnotation, useGetConductTypesForSchoolQuery } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { ConductTypeTagSelectProps } from './ConductTypeTagSelect';

type ConductTypeExpandedSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedValue: string[];
  onClear: () => void;
  onSelectId: (v: string) => void;
  onClose: () => void;
}>;

export const ConductTypeExpandedSelect: FC<ConductTypeExpandedSelectProps> = ({
  schoolId,
  selectedValue,
  onSelectId,
  onClose,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const { data } = useGetConductTypesForSchoolQuery(schoolId, { refetchOnMount: 'always' });

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const positive = data
      .filter((ct) => ct.connotation === ConductConnotation.POSITIVE)
      .filter((ct) => ct.name.toLowerCase().includes(query.toLowerCase()));
    const negative = data
      .filter((ct) => ct.connotation === ConductConnotation.NEGATIVE)
      .filter((ct) => ct.name.toLowerCase().includes(query.toLowerCase()));

    if (!positive.length && !negative.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <Stack gap={2} m={1}>
        {!!positive.length && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage id="conduct-Type-positive" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {positive.map((option) => (
                <TagSelect
                  key={option.id}
                  label={option.name}
                  variant={selectedValue?.includes(option.id) ? 'filled' : undefined}
                  onClick={() => onSelectId(option.id)}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {!!negative.length && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage id="conduct-Type-negative" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {negative.map((option) => (
                <TagSelect
                  key={option.id}
                  label={option.name}
                  variant={selectedValue?.includes(option.id) ? 'filled' : undefined}
                  onClick={() => onSelectId(option.id)}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }, [data, onSelectId, query, selectedValue]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedValue.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderConductTypeTags({
        ids: selectedValue,
        tagProps: (id: string) => {
          return {
            size: 'small',
            label: data?.find((el) => el.id === id)?.name,
          };
        },
        onDelete: onSelectId,
      })}

      <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
    </ExpandedSelect>
  );
};

type RenderConductTypeTagsParams = {
  ids: string[];
  onDelete?: (v: string) => void;
  onClick?: (v: string) => void;
  tagProps?:
    | Omit<ConductTypeTagSelectProps, 'id'>
    | ((v: string) => Omit<ConductTypeTagSelectProps, 'id'>);
};

export const renderConductTypeTags = ({
  ids,
  onDelete,
  onClick,
  tagProps,
}: RenderConductTypeTagsParams) => {
  return ids.map((v) => (
    <TagSelect
      key={v}
      id={v}
      label={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
};
