import { Stack, Typography } from '@mui/material';
import {
  CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
  ConductSelectTypesSelectedValue,
  ConductVisibility,
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  FilterSection,
  GetConductEntriesQueryFilters,
  SchoolYear,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  ConductTypeExpandedSelect,
  ConductTypeOptionsExpandedSelect,
  ConductVisibilityExpandedSelect,
  ConductVisibilityTagSelect,
  DateRangeDropdown,
  FilterDropdown,
  filterExistingFilterOptions,
  FiltersContainer,
  getSelectedItemsWithGrouping,
  GroupExpandedSelect,
  GroupTagSelect,
  MoreButton,
  MoreButtonOption,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  PropertyTypeExpandedSelect,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  toggleMultipleValueArrayProperty,
  UserExpandedSelect,
  UserTagSelect,
} from '@schooly/components/filters';
import { PROPERTIES_TEXT_IDS, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { CrossSmallIcon, TagSelect } from '@schooly/style';
import { format } from 'date-fns';
import isEqual from 'lodash.isequal';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useConductCommon } from '../../context/conduct/useConductCommon';
import { useSchool } from '../../hooks/useSchool';

type ConductEntriesFiltersProps = {
  groupBy: FilterKeys.Student | null;
  conductSelectTypes: ConductSelectTypesSelectedValue[] | null;
  onSetGroupBy: Dispatch<SetStateAction<FilterKeys.Student | null>>;
  schoolId: string;
  filters: GetConductEntriesQueryFilters;
  defaultFilters: GetConductEntriesQueryFilters;
  onSetFilters: (v: GetConductEntriesQueryFilters) => void;
  onSetConductTypeOptions: (v: ConductSelectTypesSelectedValue[] | null) => void;
  defaultSchoolYear?: SchoolYear;
  defaultUserFilters: GetConductEntriesQueryFilters;
  defaultUserGroupBy: FilterKeys.Student | null;
  defaultConductTypeOptions: ConductSelectTypesSelectedValue[] | null;
};

export const ConductEntriesFilters: FC<ConductEntriesFiltersProps> = ({
  groupBy,
  conductSelectTypes,
  onSetGroupBy,
  onSetConductTypeOptions,
  defaultFilters,
  onSetFilters,
  filters: actualFilters,
  schoolId,
  defaultSchoolYear,
  defaultUserFilters,
  defaultUserGroupBy,
  defaultConductTypeOptions,
}) => {
  const { $t } = useIntl();
  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);

  const { currentStaff, permissions } = useAuth();
  const { types } = useConductCommon();

  const [draftFilters, setDraftFilters] = useState<GetConductEntriesQueryFilters>(actualFilters);
  const [draftGroupBy, setDraftGroupBy] = useState<FilterKeys.Student | null>(groupBy);
  const [draftConductTypeOptions, setDraftConductTypeOptions] =
    useState<ConductSelectTypesSelectedValue[] | null>(conductSelectTypes);

  const { hasHouses } = useSchool();
  const isGroupViewer = permissions.includes('group_viewer');

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  const handleToggleGroupBy = useCallback(() => {
    setDraftGroupBy((v) => (v ? null : FilterKeys.Student));
  }, []);

  const handleToggleConductTypeOptions = useCallback(() => {
    setDraftConductTypeOptions(() => {
      return draftConductTypeOptions === null ? [] : null;
    });
  }, [draftConductTypeOptions, setDraftConductTypeOptions]);

  useEffect(() => {
    setDraftGroupBy(groupBy);
    setDraftFilters(actualFilters);
    setDraftConductTypeOptions(conductSelectTypes);
  }, [actualFilters, groupBy, conductSelectTypes]);

  const handleApply = useMemo(() => {
    if (
      !CONDUCT_ENTRIES_QUERY_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      }) &&
      groupBy === draftGroupBy &&
      isEqual(conductSelectTypes, draftConductTypeOptions)
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
      onSetGroupBy(draftGroupBy);
      onSetConductTypeOptions(draftConductTypeOptions);
    };
  }, [
    groupBy,
    draftGroupBy,
    draftFilters,
    actualFilters,
    conductSelectTypes,
    draftConductTypeOptions,
    onSetFilters,
    onSetGroupBy,
    onSetConductTypeOptions,
  ]);

  const dateFilter = useMemo(
    () => ({ [FilterKeys.IntersectDate]: draftFilters.date }),
    [draftFilters.date],
  );

  const {
    onSetDate,
    onSelectGroup,
    onClearGroup,
    onClearAgeGroup,
    onClearConductType,
    onClearHouse,
    onClearStudent,
    onSelectAgeGroup,
    onSelectConductTypeId,
    onSelectHouseId,
    onSelectStudentId,
  } = useMemo(() => {
    const updateFilter =
      (key: FilterKeys.Group | FilterKeys.House | FilterKeys.Student | FilterKeys.ConductType) =>
      (id: string) => {
        setDraftFilters((filters) => ({
          ...filters,
          [key]: filters[key]?.includes(id)
            ? filters[key]?.filter((ct) => ct !== id)
            : [...(filters[key] || []), id],
        }));
      };

    const onSelectAgeGroup = (v: string[]) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };

    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSetDate: (v: [Date, Date]) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.Date]: [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ],
        })),
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onSelectHouseId: updateFilter(FilterKeys.House),
      onClearHouse: clearFilter(FilterKeys.House),
      onSelectConductTypeId: updateFilter(FilterKeys.ConductType),
      onClearConductType: clearFilter(FilterKeys.ConductType),
      onSelectGroup: updateFilter(FilterKeys.Group),
      onClearGroup: clearFilter(FilterKeys.Group),
      onSelectStudentId: updateFilter(FilterKeys.Student),
      onClearStudent: clearFilter(FilterKeys.Student),
    };
  }, []);

  const { onSelectConductStatus, onClearConductStatus } = useMemo(() => {
    return {
      onSelectConductStatus: (id: ConductVisibility) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.ConductStatus]: filters[FilterKeys.ConductStatus]?.includes(id)
            ? filters[FilterKeys.ConductStatus]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.ConductStatus] || []), id],
        })),
      onClearConductStatus: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.ConductStatus]: [],
        })),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
    setDraftGroupBy(null);
    setDraftConductTypeOptions(null);
  }, [defaultFilters]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
    setDraftGroupBy(defaultUserGroupBy);
    setDraftConductTypeOptions(defaultConductTypeOptions);
  }, [defaultUserFilters, defaultUserGroupBy, defaultConductTypeOptions]);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const filtersDate = draftFilters.date;
  const filtersGroup = isGroupViewer ? draftFilters.group : undefined;
  const filtersAgeGroup = draftFilters.age_group;
  const filtersHouse = draftFilters.house;
  const filtersVisbility = draftFilters.visibility;
  const filtersStudent = draftFilters.student;
  const filtersConductType = draftFilters.conduct_type;

  const dateLabel = $t({ id: 'schoolProperty-Period' });
  const groupLabel = $t({ id: 'asssessments-Group' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });
  const houseLabel = $t({ id: 'schoolProperty-House' });
  const visibilityLabel = $t({ id: 'visibility' });
  const studentLabel = $t({ id: 'schoolProperty-Student' });
  const conductTypeLabel = $t({ id: 'conduct-ConductType' });
  const conductTypeOptionsLabel = $t({ id: 'conduct-ConductTypeOptions' });

  const filterOptions: MoreButtonOption<keyof GetConductEntriesQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.ConductStatus, label: visibilityLabel },
    { value: FilterKeys.ConductType, label: conductTypeLabel },
    { value: FilterKeys.Student, label: studentLabel },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
    { value: FilterKeys.House, label: houseLabel },
    { value: FilterKeys.Group, label: groupLabel, visible: isGroupViewer },
  ];

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      filtersAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filtersAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const selectedConductTypeDraftOptions = useMemo(() => {
    const res = draftConductTypeOptions?.map((v) => v.conduct_type_options).flat();
    return res;
  }, [draftConductTypeOptions]);

  const onSelectConductTypeOptions = (conductTypeId: string, conductTypeOption: string) => {
    setDraftConductTypeOptions((options) => {
      const hasOption = options?.find((option) => option.conduct_type_id === conductTypeId);

      if (hasOption) {
        return (options || [])
          .map((option) => {
            if (option.conduct_type_id === conductTypeId) {
              return {
                conduct_type_id: conductTypeId,
                conduct_type_options: toggleMultipleValueArrayProperty(
                  option.conduct_type_options,
                  [conductTypeOption],
                ),
              };
            }

            return option;
          })
          .filter((option) => option.conduct_type_options.length);
      } else {
        return [
          ...(options?.filter((option) => option.conduct_type_options.length) || []),
          {
            conduct_type_id: conductTypeId,
            conduct_type_options: [conductTypeOption],
          },
        ];
      }
    });

    if (!draftFilters[FilterKeys.ConductType]?.includes(conductTypeId)) {
      onSelectConductTypeId(conductTypeId);
    }
  };

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Conduct}
        accessMap={{
          group: isGroupViewer,
        }}
        filters={{
          ...draftFilters,
          group_by: draftGroupBy || undefined,
          conduct_select_types: draftConductTypeOptions || undefined,
        }}
        defaultSchoolYear={defaultSchoolYear}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(CONDUCT_ENTRIES_QUERY_FILTER_KEYS, v));

          if (!v.group_by) {
            onSetGroupBy(null);
          }
          if (v.group_by === FilterKeys.Student) onSetGroupBy(FilterKeys.Student);

          onSetConductTypeOptions(v.conduct_select_types || null);
        }}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          onSetDate={onSetDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
        />
      )}

      {filtersVisbility && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.ConductStatus)}
          label={visibilityLabel}
          tags={(open) =>
            filtersVisbility.map((v) => (
              <ConductVisibilityTagSelect
                sx={{ maxWvth: 200 }}
                key={v}
                visibility={v}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <ConductVisibilityExpandedSelect
              selectedVisibility={filtersVisbility}
              onSelectConductVisibility={onSelectConductStatus}
              onClose={onClose}
              onClear={onClearConductStatus}
            />
          )}
        </FilterDropdown>
      )}
      {filtersConductType && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.ConductType)}
          label={conductTypeLabel}
          tags={(open) =>
            filtersConductType.map((id) => (
              <TagSelect
                sx={{ maxWidth: 200 }}
                key={id}
                id={id}
                onClick={open}
                label={types?.find((type) => type.id === id)?.name}
              />
            ))
          }
        >
          {(onClose) => (
            <ConductTypeExpandedSelect
              schoolId={schoolId}
              selectedValue={filtersConductType}
              onSelectId={onSelectConductTypeId}
              onClose={onClose}
              onClear={onClearConductType}
            />
          )}
        </FilterDropdown>
      )}
      {filtersStudent && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Student)}
          label={studentLabel}
          tags={(open) =>
            filtersStudent.map((id) => (
              <UserTagSelect
                schoolId={schoolId}
                userType="student"
                sx={{ maxWidth: 200 }}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <UserExpandedSelect
              schoolId={schoolId}
              type="student"
              selectedIds={filtersStudent}
              onSelectUserId={onSelectStudentId}
              onClose={onClose}
              onClear={onClearStudent}
              filters={{ [FilterKeys.Date]: filtersDate }}
            />
          )}
        </FilterDropdown>
      )}
      {filtersAgeGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: open,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200 },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      {filtersHouse && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.House)}
          label={houseLabel}
          tags={(open) =>
            filtersHouse.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.House}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersHouse}
              onSelectId={onSelectHouseId}
              onClose={onClose}
              onClear={onClearHouse}
            />
          )}
        </FilterDropdown>
      )}
      {filtersGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Group)}
          label={groupLabel}
          width={500}
          tags={(open) =>
            filtersGroup.map((id) => (
              <GroupTagSelect key={id} sx={{ maxWidth: 200 }} id={id} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <GroupExpandedSelect
              selectedIds={filtersGroup}
              schoolId={schoolId}
              onSelectGroup={onSelectGroup}
              onClose={onClose}
              onClear={onClearGroup}
              filters={dateFilter}
            />
          )}
        </FilterDropdown>
      )}
      {draftGroupBy && (
        <Stack
          flexDirection="row"
          gap={0.5}
          alignItems="center"
          onClick={() => setDraftGroupBy(null)}
          sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
        >
          <Typography variant="h3" color="common.grey2">
            {$t({ id: 'filter-GroupBy' })}:
          </Typography>
          <Typography variant="h3">{$t({ id: PROPERTIES_TEXT_IDS[draftGroupBy] })}</Typography>
          <CrossSmallIcon color="black" />
        </Stack>
      )}
      {draftConductTypeOptions !== null && (
        <FilterDropdown
          onClear={() => setDraftConductTypeOptions(null)}
          label={conductTypeOptionsLabel}
          tags={(open) =>
            (selectedConductTypeDraftOptions || []).map((id) => (
              <TagSelect label={id} sx={{ maxWidth: 200 }} key={id} id={id} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <ConductTypeOptionsExpandedSelect
              schoolId={schoolId}
              selectedValue={draftConductTypeOptions}
              onSelectOption={onSelectConductTypeOptions}
              onClose={onClose}
              onClear={() => setDraftConductTypeOptions(null)}
            />
          )}
        </FilterDropdown>
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterExistingFilterOptions({ filterOptions, hasHouses })}
        groupBy={FilterKeys.Student}
        isSelectedGroupBy={!!draftGroupBy}
        onToggleGroupBy={handleToggleGroupBy}
        isSelectedConductTypeOptions={draftConductTypeOptions !== null}
        onToggleConductTypeOptions={handleToggleConductTypeOptions}
        selectedOptions={CONDUCT_ENTRIES_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};
