import { Box, Icon, Stack, Typography } from '@mui/material';
import {
  addEntryComment,
  ApiError,
  DATE_FORMAT_FULL_MONTH_FNS,
  DATE_FORMAT_SHORT_FNS,
  editEntryComment,
  SchoolYear,
  SORT_DIRECTION,
  useGetConductEntriesQuery,
} from '@schooly/api';
import { ConductEntry, ConductVisibility } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { MD_BREAKPOINT_TABLE_MIN_WIDTH } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { EditIcon, ModalSearch, PlusIcon, TypographyWithOverflowHint } from '@schooly/style';
import { Loading } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { DropdownCommentsItem } from '../../../components/uikit-components/DropdownComments/DropdownComments';
import { DropdownComments } from '../../../components/uikit-components/DropdownCommentsV2/DropdownComments';
import { CommentRowCell } from '../../../components/uikit-components/DropdownCommentsV2/DropdownCommentsWrappers/CommentRowCell';
import {
  GridContainer,
  GridRowCell,
  GridRowDate,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../components/uikit-components/Grid/Grid';
import { LongNameWithVerticalTooltip } from '../../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { MONTH_NONE } from '../../../constants/misc';
import { useProfile } from '../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../helpers/misc';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { ConductConnotationIndicator } from '../../Conduct/ConductGrid';
import { ConductVisibilityButton } from '../../Conduct/ConductVisibilityButton';
import { ProfileModalConductExport } from './ProfileModalConductExport';

export const ProfileModalConduct: FC = () => {
  const { id } = useParams<'id'>();
  const { defaultValidity } = useSchoolYears();
  const { formatMessage } = useIntl();
  const { schoolMembership } = useProfile();

  const [schoolYear, setSchoolYear] = useState<SchoolYear | undefined>(defaultValidity);

  const { showError } = useNotifications();
  const { schoolId = '', currentStaff, permissions } = useAuth();

  const [pendingEntriesId, setPendingEntriesId] = useState<ConductEntry['id'][]>([]);

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    params,
    setParams,
    refetch,
  } = useGetConductEntriesQuery(
    {
      schoolId,
      pageSize: 50,
      sort: { columnTextId: 'date', direction: SORT_DIRECTION.DESC },
      filters: {
        student: [id!],
        date: schoolYear ? [schoolYear.start, schoolYear.end] : undefined,
      },
      query: '',
    },
    { enabled: !!id, refetchOnMount: 'always' },
  );

  const entries = useMemo(
    () => data?.pages.reduce<ConductEntry[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const handleChangeSchoolYear = useCallback(
    (v: SchoolYear) => {
      setSchoolYear(v);
      setParams((p) => ({
        ...p,
        filters: { ...p.filters, date: [v.start, v.end] },
      }));
    },
    [setParams],
  );

  const setEntryComment = useCallback(
    async (entryId: string, commentId?: string, comment?: string) => {
      if (!currentStaff || typeof comment === 'undefined') {
        return;
      }

      const entry = entries?.find((entry) => entry.id === entryId);

      if (!entry) {
        return;
      }

      const existingComment = commentId
        ? entry.comments.find((comment) => comment.creator_relation_id === commentId)
        : undefined;

      if (existingComment && existingComment.comment === comment) {
        return;
      }

      setPendingEntriesId((old) => [...old, entryId]);

      try {
        if (existingComment) {
          await editEntryComment(entryId, existingComment.id, {
            comment,
            creator_relation_id: existingComment.creator_relation_id,
          });
        } else {
          await addEntryComment(entryId, {
            comment,
            creator_relation_id: currentStaff.relation_id,
          });
        }
        await refetch();
        setPendingEntriesId((old) => old.filter((id) => id !== entryId));
      } catch (err) {
        showError(err as ApiError);
        setPendingEntriesId((old) => old.filter((id) => id !== entryId));
      }
    },
    [currentStaff, entries, refetch, showError],
  );

  const canView = permissions.includes('conduct_viewer');
  const canEdit = permissions.includes('conduct_manager');

  const entriesByMonth = useMemo(() => {
    return (
      entries?.reduce<Record<string, ConductEntry[]>>((prev, entry) => {
        const month = entry.date
          ? format(newDateTimezoneOffset(entry.date), DATE_FORMAT_FULL_MONTH_FNS).toUpperCase()
          : MONTH_NONE;

        if (!prev[month]) {
          prev[month] = [];
        }

        prev[month].push(entry);

        return prev;
      }, {}) ?? {}
    );
  }, [entries]);

  const { schoolYears } = useSchoolYears();

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);
  const total = data?.pages[0].count || 0;

  const handleCommentAdd = useCallback(
    (entry: ConductEntry) => (comment: string) => {
      if (comment) {
        setEntryComment(entry.id, undefined, comment);
      }
    },
    [setEntryComment],
  );

  const handleCommentEdit = useCallback(
    (entry: ConductEntry) => (comment: string, relationId: string) => {
      setEntryComment(entry.id, relationId, comment);
    },
    [setEntryComment],
  );

  const handleSetQuery = useCallback(
    (query: string) => setParams((p) => ({ ...p, query })),
    [setParams],
  );

  const renderContent = () => {
    if (!data) {
      return <Loading />;
    }
    if (!total)
      return (
        <Stack height="100%">
          <NoSearchResultsFound type="small" />
        </Stack>
      );

    return (
      <>
        <Stack
          gap={2.5}
          sx={(theme) => ({
            overflowY: 'scroll',
            height: '100%',
            [theme.breakpoints.down('md')]: {
              mr: -2.5,
            },
          })}
        >
          {Object.keys(entriesByMonth).map((month) => (
            <Box key={month}>
              {month !== MONTH_NONE && <Typography variant="h4">{month}</Typography>}
              <Stack
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    overflowX: 'auto',
                    pr: 2.5,
                  },
                })}
              >
                <GridContainer
                  sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                      minWidth: MD_BREAKPOINT_TABLE_MIN_WIDTH,
                    },
                  })}
                >
                  {entriesByMonth[month].map((entry) => {
                    const comments = entry.comments.map<DropdownCommentsItem>((comment) => ({
                      id: comment.id,
                      comment: comment.comment,
                      relation_id: comment.creator_relation_id,
                      title: comment.creator_title,
                      last_name: comment.creator_last_name,
                      given_name: comment.creator_given_name,
                      known_as: comment.creator_known_as,
                    }));

                    const canShowVisibilityIcon = entry.visibility === ConductVisibility.PUBLISHED;

                    const renderMainCells = () => (
                      <>
                        <GridRowDate
                          sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: {
                              flex: '0 0 20px',
                            },
                          })}
                        >
                          {format(newDateTimezoneOffset(entry.date), DATE_FORMAT_SHORT_FNS)}
                        </GridRowDate>
                        <GridRowCell sx={{ flex: '0 0 30px' }}>
                          {canShowVisibilityIcon && (
                            <ConductVisibilityButton
                              tooltipTitle={<FormattedMessage id="conduct-parents-visible" />}
                              selected
                              nowrap
                            />
                          )}
                        </GridRowCell>
                        <GridRowCell sx={{ flex: '0 0 130px', width: 130 }}>
                          <Stack direction="row" alignItems="center" gap={1} whiteSpace="nowrap">
                            <ConductConnotationIndicator
                              connotation={entry.conduct_type.connotation}
                            />
                            <TypographyWithOverflowHint>
                              {entry.conduct_type.name}
                            </TypographyWithOverflowHint>
                          </Stack>
                        </GridRowCell>
                        <GridRowName
                          variant="body1"
                          sx={(theme) => ({
                            flex: '0 0 100px',
                            [theme.breakpoints.down('md')]: {
                              flex: '0 0 70px',
                            },
                          })}
                        >
                          {entry.value || entry.title}
                        </GridRowName>
                        <GridRowName variant="body1" sx={{ overflow: 'hidden' }}>
                          <LongNameWithVerticalTooltip>{entry.details}</LongNameWithVerticalTooltip>
                        </GridRowName>

                        <CommentRowCell
                          rowStyleProps={{
                            flex: '0 0 30px',
                          }}
                        >
                          {(onToggle, cellRef) => (
                            <DropdownComments
                              comments={comments}
                              onAdd={handleCommentAdd(entry)}
                              onEdit={handleCommentEdit(entry)}
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }}
                              disabled={pendingEntriesId.includes(entry.id)}
                              canAdd={canEdit}
                              canEditOwn={canEdit}
                              canEditOther={canEdit}
                              getParentRef={() => cellRef}
                              popoverMargin={2}
                              onToggle={onToggle}
                            />
                          )}
                        </CommentRowCell>
                        <GridRowName
                          sx={(theme) => ({
                            flex: '0 0 120px',
                            overflow: 'hidden',
                            [theme.breakpoints.down('md')]: {
                              display: 'none',
                            },
                          })}
                        >
                          <Stack
                            component={Link}
                            to={getRouteModalPathname('staff', entry.staff)}
                            direction="row"
                            onClick={(e) => e.stopPropagation()}
                            color="common.grey2"
                            alignItems="center"
                            sx={{
                              ':hover': {
                                '*': {
                                  textDecoration: 'underline',
                                },
                              },
                            }}
                          >
                            <TypographyWithOverflowHint color="common.grey2" minWidth={120}>
                              {getUserFullName(entry.staff)}
                            </TypographyWithOverflowHint>
                          </Stack>
                        </GridRowName>
                      </>
                    );

                    return canEdit ? (
                      <GridRowStyled key={entry.id}>
                        <Link to={`/conduct/${entry.id}/edit`}>
                          <GridRowItem
                            noVerticalPadding
                            gap={1}
                            sx={(theme) => ({
                              cursor: 'pointer',
                              '& .edit-icon': { display: 'none' },
                              '&:hover .edit-icon': { display: 'inline-block' },
                              [theme.breakpoints.down('lg')]: {
                                '& .edit-icon': {
                                  display: 'unset',
                                  color: theme.palette.text.secondary,
                                },
                              },
                              [theme.breakpoints.down('md')]: {
                                minHeight: 40,
                              },
                            })}
                          >
                            {renderMainCells()}
                            <GridRowCell
                              sx={{
                                flex: '0 0 30px',
                              }}
                            >
                              <Icon className="edit-icon">
                                <EditIcon />
                              </Icon>
                            </GridRowCell>
                          </GridRowItem>
                        </Link>
                      </GridRowStyled>
                    ) : (
                      <GridRowStyled key={entry.id}>
                        <GridRowItem noVerticalPadding>{renderMainCells()}</GridRowItem>
                      </GridRowStyled>
                    );
                  })}
                </GridContainer>
              </Stack>
            </Box>
          ))}
        </Stack>

        {hasNextPage && (
          <Box py={3}>
            <Loading />
            <div ref={loaderRef} />
          </Box>
        )}
      </>
    );
  };

  return canView ? (
    <div className="section section-wrapper">
      <Stack direction="row" justifyContent="space-between" mb={2.5} alignItems="center" mt={-0.75}>
        <Typography variant="h2">
          <FormattedMessage id="section-Conduct" />
        </Typography>

        <Stack direction="row" alignItems="center" gap={2.5}>
          <ModalSearch
            withDebounce
            value={params.query || ''}
            onChange_MemoizedCallbackOnly={handleSetQuery}
            placeholder={formatMessage({ id: 'people-Search' })}
          />
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={schoolYear}
            onYearChange={handleChangeSchoolYear}
          />
          <ProfileModalConductExport schoolYear={schoolYear} />
          {canEdit && (
            <Link to={`/conduct/new?relation_id=${schoolMembership?.relation_id}`}>
              <SimpleButton startIcon={<PlusIcon />}>
                <FormattedMessage id="conduct-NewLog" />
              </SimpleButton>
            </Link>
          )}
        </Stack>
      </Stack>

      {renderContent()}
    </div>
  ) : (
    <Stack height="100%" justifyContent="center">
      <AccessDenied />
    </Stack>
  );
};
