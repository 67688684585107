import { Currencies, SchoolInviteStatus } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { Company } from './apiTypes/companies';
import { IColumnSort } from './apiTypes/endpoints/people';
import { ApiError, PagedResponse, SORT_DIRECTION, StringOrNull } from './apiTypes/misc';
import { WithAvatar, WithName } from './apiTypes/partials';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { SchoolRelation } from './apiTypes/relations';
import { SchoolYear } from './apiTypes/schools';
import { FilterKeys, Guardian } from './apiTypes/users';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

type AccountType = 'internal' | 'xero';

type Account<T extends AccountType> = {
  id: string;
  name: string;
  type: T;
};

export type InternalAccount = Account<'internal'>;

export type XeroAccount = {
  xero_tenant_name: string;
  xero_tenant_id: string;
} & Account<'xero'>;

export type XeroAccountInProduct = Omit<XeroAccount, 'xero_tenant_name' | 'xero_tenant_id'>;

export enum ProductTriggerType {
  RegistrationUpdate = 'registration-update',
  SingleInvoice = 'single-invoice',
}

export type ProductVariantPrice = {
  price: number;
  frequency_id: string;
};

export type ProductVariant = {
  id: string;
  age_groups: string[];
  subjects: string[];
  half_day: boolean;
  prices: ProductVariantPrice[];
};

export type ProductType = {
  id: string;
  name: string;
  variants: ProductVariant[];
  active_from: string;
  active_to: string;
  billing_connection: {
    legal_entity_account: InternalAccount | XeroAccountInProduct;
    legal_entity_id: string;
    legal_entity_display_name: string;
    legal_entity_currency: Currencies;
  };
};

export enum ProductBillingType {
  Recurring = 'recurring',
  OneOff = 'one_off',
}

export type ProductTrigger = {
  id: string;
  trigger_type: ProductTriggerType;
  extra_data?: { status: string };
};

export type Product = {
  id: string;
  type: ProductBillingType;
  school_id: string;
  name: string;
  description: string;
  obligatory: boolean;
  single_type: boolean;
  unique_types: boolean;
  assignment: {
    one_type: boolean;
  };
  triggers: ProductTrigger[];
  types: ProductType[];
};

export type ProductSaveVariant = {
  id?: string;
  age_groups: string[];
  subjects: string[];
  half_day: boolean;
  prices: ProductVariantPrice[];
};

type ProductSaveType = {
  id?: string;
  name: string;
  variants: ProductSaveVariant[];
  year_id?: SchoolYear['id'];
  active_from?: string;
  active_to?: string;
  billing_connection: {
    account_id: string;
  };
};

export type ProductSave = {
  name: string;
  type: ProductBillingType;
  description: string;
  obligatory: boolean;
  single_type: boolean;
  unique_types: boolean;
  assignment: {
    one_type: boolean;
  };
  triggers: Omit<ProductTrigger, 'id'>[];
  types: ProductSaveType[];
};

export type ProductFormType = Omit<ProductSaveType, 'billing_connection'> & {
  billing_connection: {
    account_id: string;
    legal_entity_id: string;
    legal_entity_currency?: Currencies;
  };
};

export type ProductForm = Omit<ProductSave, 'types'> & {
  types: ProductFormType[];
};

export type GetProductsQueryFilters = {};

export const PRODUCTS_QUERY_FILTER_KEYS = [] as const;

const DEFAULT_PAGE_SIZE = 30;
const INVOICES_URL = '/invoices';

export type GetSchoolAccountsResponse = Array<InternalAccount | XeroAccount>;

export const getSchoolAccounts = async (schoolId: string): Promise<GetSchoolAccountsResponse> => {
  return await api.get(`${INVOICES_URL}/accounts/${schoolId}`);
};

export const GET_SCHOOL_ACCOUNTS = `${INVOICES_URL}/GET_SCHOOL_ACCOUNTS`;

export const useGetSchoolAccounts = (
  schoolId: string,
  options?: RQUseQueryOptions<GetSchoolAccountsResponse>,
) => {
  return useQuery<GetSchoolAccountsResponse, ApiError>(
    [GET_SCHOOL_ACCOUNTS, schoolId],
    () => getSchoolAccounts(schoolId),
    {
      ...options,
    },
  );
};

export const getProduct = async (id: string): Promise<Product> => {
  return api.get(`${INVOICES_URL}/products/${id}`);
};

export const GET_PRODUCT_QUERY = `${INVOICES_URL}/GET_PRODUCT_QUERY`;

export const useGetProductQuery = (id: string, options?: RQUseQueryOptions<Product>) => {
  return useQuery<Product, ApiError>([GET_PRODUCT_QUERY, id], () => getProduct(id), {
    ...options,
  });
};

export type GetProductsSort = IColumnSort<keyof Pick<Product, 'name'>>;

export type GetProductsParams = {
  schoolId: string;
  query: string;
  pageSize?: number;
  sort?: GetProductsSort[];
  pageNumber?: number;
  relationId?: string;
  legalEntityIds?: string[];
  type?: ProductBillingType;
};

export const getProducts = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  relationId,
  legalEntityIds,
  type,
}: GetProductsParams): Promise<PagedResponse<Product>> => {
  return api.get(`${INVOICES_URL}/products/for-school/${schoolId}`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      relation_id: relationId,
      legalEntityIds: legalEntityIds?.join(','),
      type,
    },
  });
};

export const GET_PRODUCTS_QUERY = `${INVOICES_URL}/GET_PRODUCTS_QUERY`;

export const useGetProductsListQuery = (
  initialParams: Omit<GetProductsParams, 'sort'> & {
    sort?: GetProductsSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Product>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Product>, ApiError>(
    [GET_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getProducts({
        pageNumber: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type CreateProductParams = {
  schoolId: string;
  product: ProductSave;
  withActiveRegistrations?: boolean;
};

export type CreateProductResponse = Product;

export const createProduct = ({
  schoolId,
  withActiveRegistrations,
  ...params
}: CreateProductParams): Promise<CreateProductResponse> => {
  const withActiveRegistrationsParam =
    withActiveRegistrations !== undefined
      ? `?with_active_registrations=${withActiveRegistrations}`
      : '';

  return api.post(
    `${INVOICES_URL}/products/for-school/${schoolId}${withActiveRegistrationsParam}`,
    { ...params.product },
  );
};

export const useCreateProductMutation = (
  options?: RQUseMutationOptions<CreateProductResponse, CreateProductParams>,
): RQUseMutationResult<CreateProductResponse, CreateProductParams> => {
  return useMutation(createProduct, {
    ...options,
  });
};

export type UpdateProductParams = {
  id: string;
  product: ProductSave;
};

export type UpdateProductResponse = { success: string; product: Product };

export const updateProduct = ({
  id,
  ...params
}: UpdateProductParams): Promise<UpdateProductResponse> => {
  return api.patch(`${INVOICES_URL}/products/${id}`, {
    ...params.product,
  });
};

export const useUpdateProductMutation = (
  options?: RQUseMutationOptions<UpdateProductResponse, UpdateProductParams>,
): RQUseMutationResult<UpdateProductResponse, UpdateProductParams> => {
  return useMutation(updateProduct, {
    ...options,
  });
};

export type DeleteProductParams = {
  id: string;
};

export type DeleteProductResponse = { success: string };

export const deleteProduct = ({ id }: DeleteProductParams): Promise<DeleteProductResponse> => {
  return api.remove(`${INVOICES_URL}/products/${id}`);
};

export const useDeleteProductMutation = (
  options?: RQUseMutationOptions<DeleteProductResponse, DeleteProductParams>,
): RQUseMutationResult<DeleteProductResponse, DeleteProductParams> => {
  return useMutation(deleteProduct, {
    ...options,
  });
};

type CheckProductNameUniqueParams = {
  schoolId: string;
  name: string;
};

type CheckProductNameUniqueResponse = {
  is_unique: boolean;
};

const checkProductNameUnique = (
  params: CheckProductNameUniqueParams,
): Promise<CheckProductNameUniqueResponse> => {
  return api.post(`${INVOICES_URL}/products/for-school/${params.schoolId}/validate-name`, {
    name: params.name,
  });
};

export const useCheckProductNameUniqueMutation = (
  options?: RQUseMutationOptions<CheckProductNameUniqueResponse, CheckProductNameUniqueParams>,
): RQUseMutationResult<CheckProductNameUniqueResponse, CheckProductNameUniqueParams> => {
  return useMutation(checkProductNameUnique, {
    ...options,
  });
};

export enum PayerType {
  Default = 'default',
  Company = 'company',
}

export type DefaultPayer = {
  relation_id: string;
  email?: string;
  telephone?: string;
  invite_status: SchoolInviteStatus | null;
} & WithName &
  WithAvatar;

export type AssignedProduct = {
  id: string;
  product_type: ProductBillingType;
  name: string;
  obligatory: boolean;
  payer_type: PayerType;
  discount_percent?: number;
  discounted_price?: number;
  variant: {
    id: string;
    price: number;
    currency: Currencies;
    frequency_id: string;
    type_name: string;
  };
};

export type AssignedProductWithAvailableVariants = AssignedProduct & {
  available_variants: AssignedProductAvailableVariant[];
};

export type AssignedProductAvailableVariant = {
  type_id: string;
  type_name: string;
  id: string;
  half_day: boolean;
  prices: ProductVariantPrice[];
  currency: Currencies;
};

type GetStudentProductsParams = {
  relationId: string;
  type?: ProductBillingType;
};

type GetStudentProductsResponse = {
  default_payer: DefaultPayer;
  company_payer?: Company;
  products: AssignedProductWithAvailableVariants[];
};

const getStudentProducts = async ({
  relationId,
  ...params
}: GetStudentProductsParams): Promise<GetStudentProductsResponse> => {
  return api.get(`${INVOICES_URL}/products/for-relation/${relationId}`, { params });
};

export const GET_STUDENT_PRODUCTS_QUERY = `${INVOICES_URL}/GET_STUDENT_PAYERS_QUERY`;

export const useGetStudentProductsQuery = (
  params: GetStudentProductsParams,
  options?: RQUseQueryOptions<GetStudentProductsResponse>,
) => {
  return useQuery<GetStudentProductsResponse, ApiError>(
    [GET_STUDENT_PRODUCTS_QUERY, params],
    () => getStudentProducts(params),
    {
      ...options,
    },
  );
};

export interface StudentWithProducts extends WithName, WithAvatar {
  relation_id: string;
  default_payer: DefaultPayer;
  company_payer?: Company;
  products: AssignedProduct[];
}

type GetStudentsWithProductsSort = IColumnSort<
  keyof Pick<StudentWithProducts, 'last_name' | 'given_name'>
>;

interface GetStudentsWithProductsFilters {
  [FilterKeys.Status]?: string[];
  [FilterKeys.AgeGroup]?: string[];
}

interface GetStudentsWithProductsParams {
  schoolId: string;
  query: string;
  pageSize?: number;
  sort?: GetStudentsWithProductsSort[];
  pageNumber?: number;
  filters?: Partial<GetStudentsWithProductsFilters>;
}

const getStudentsWithProducts = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  filters,
}: GetStudentsWithProductsParams): Promise<PagedResponse<StudentWithProducts>> => {
  const baseQueryParams = filters
    ? removeObjectUndefinedNullValues({
        status_ids: filters?.status?.join(','),
        age_group: filters?.age_group?.join(','),
      })
    : {};

  return api.get(`${INVOICES_URL}/students/for-school/${schoolId}`, {
    params: {
      ...baseQueryParams,
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
    },
  });
};

export const GET_STUDENTS_WITH_PRODUCTS_QUERY = `${INVOICES_URL}/GET_STUDENTS_WITH_PRODUCTS_QUERY`;

export const useGetStudentsWithProductsListQuery = (
  initialParams: Omit<GetStudentsWithProductsParams, 'sort'> & {
    sort?: GetStudentsWithProductsSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentWithProducts>>,
) => {
  const [params, setParams] = useState(initialParams);

  const filters = params.filters ? removeObjectEmptyArrayValues(params.filters) : {};

  const query = useInfiniteQuery<PagedResponse<StudentWithProducts>, ApiError>(
    [GET_STUDENTS_WITH_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getStudentsWithProducts({
        pageNumber: pageParam,
        ...params,
        filters,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export interface StudentForCompanyRelation extends WithName, WithAvatar {
  id: string;
}

export interface StudentForCompany {
  relation: StudentForCompanyRelation;
  products: AssignedProduct[];
}

type GetStudentsForCompanySort = IColumnSort<
  keyof Pick<StudentForCompanyRelation, 'last_name' | 'given_name'>
>;

interface GetStudentsForCompanyParams {
  query: string;
  pageSize?: number;
  sort?: GetStudentsForCompanySort[];
  pageNumber?: number;
  companyId: Company['id'];
  year_id?: string;
}

export const getStudentsForCompany = async ({
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  companyId,
  year_id,
}: GetStudentsForCompanyParams): Promise<PagedResponse<StudentForCompany>> => {
  return api.get(`${INVOICES_URL}/students/for-company/${companyId}`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
      year_id,
    },
  });
};

export const GET_STUDENTS_FOR_COMPANY_QUERY = `${INVOICES_URL}/GET_STUDENTS_FOR_COMPANY_QUERY`;

export const useGetStudentsForCompanyListQuery = (
  initialParams: Omit<GetStudentsForCompanyParams, 'sort'> & {
    sort?: GetStudentsForCompanySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentForCompany>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<StudentForCompany>, ApiError>(
    [GET_STUDENTS_WITH_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getStudentsForCompany({
        pageNumber: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type AssignedProductSave = {
  id: string;
  discount_percent?: number;
  payer_type: PayerType;
  variant: {
    type_name: string;
    id: string;
    frequency_id: string;
  };
};

type UpdateStudentProductsParams = {
  studentId: string;
  defaultPayerId: string;
  companyPayerId?: string;
  products: AssignedProductSave[];
};

type UpdateStudentProductsResponse = { success: string };

export const updateStudentProducts = ({
  studentId,
  defaultPayerId,
  companyPayerId,
  products,
}: UpdateStudentProductsParams): Promise<UpdateStudentProductsResponse> => {
  return api.patch(`${INVOICES_URL}/products/for-relation/${studentId}`, {
    default_payer_id: defaultPayerId,
    company_payer_id: companyPayerId,
    products,
  });
};

export const useUpdateStudentProductsMutation = (
  options?: RQUseMutationOptions<UpdateStudentProductsResponse, UpdateStudentProductsParams>,
): RQUseMutationResult<UpdateStudentProductsResponse, UpdateStudentProductsParams> => {
  return useMutation(updateStudentProducts, {
    ...options,
  });
};

export type DependantStudent = {
  id: string;
} & WithName &
  WithAvatar;

type GetDependantStudentsForParentParams = {
  id: string;
  year_id?: string;
};

export const getDependantStudentsForParent = async ({
  id,
  year_id,
}: GetDependantStudentsForParentParams): Promise<DependantStudent[]> => {
  return api.get(`${INVOICES_URL}/students/for-parent/${id}`, {
    params: { year_id },
  });
};

export const GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY = `${INVOICES_URL}/GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY`;

export const useGetDependantStudentsForParentQuery = (
  params: GetDependantStudentsForParentParams,
  options?: RQUseQueryOptions<DependantStudent[]>,
) => {
  return useQuery<DependantStudent[], ApiError>(
    [GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY, params],
    () => getDependantStudentsForParent(params),
    {
      ...options,
    },
  );
};

type GetProductsForParentParams = {
  relationId: string;
};

export type GetProductsForParentResponse = {
  student_id: string;
  product: AssignedProduct;
}[];

const getProductsForParent = async ({
  relationId,
}: GetProductsForParentParams): Promise<GetProductsForParentResponse> => {
  return api.get(`${INVOICES_URL}/products/for-parent/${relationId}`);
};

export const GET_PRODUCTS_FOR_PARENT_QUERY = `${INVOICES_URL}/GET_PRODUCTS_FOR_PARENT_QUERY`;

export const useGetProductsForParentQuery = (
  params: GetProductsForParentParams,
  options?: RQUseQueryOptions<GetProductsForParentResponse>,
) => {
  return useQuery<GetProductsForParentResponse, ApiError>(
    [GET_PRODUCTS_FOR_PARENT_QUERY, params],
    () => getProductsForParent(params),
    {
      ...options,
    },
  );
};

export type PayableFeeStatus =
  | 'upcoming'
  | 'unpaid'
  | 'partially_paid'
  | 'overdue'
  | 'paid'
  | 'voided'
  | 'cancelled';

export type GetPayableFeesStatisticsParams = {
  school_ids: string;
  year_id: string;
  relation_ids?: string[]; // any school user relation id
  query?: string;
  company_ids?: string[];
};

export type PayableFeesStatisticsItem = {
  label: PayableFeeStatus;
  value: number;
};

export type GetPayableFeesStatisticsResponse = {
  statistics: { currency: Currencies; total: number; items: PayableFeesStatisticsItem[] }[];
};

const getPayableFeesStatistics = async ({
  relation_ids,
  company_ids,
  ...rest
}: GetPayableFeesStatisticsParams): Promise<GetPayableFeesStatisticsResponse> => {
  const params = removeObjectUndefinedNullValues({
    relation_ids: relation_ids?.join(','),
    company_ids: company_ids?.join(','),
    ...rest,
  });

  return api.get(`${INVOICES_URL}/invoice/for-school/statistics`, {
    params,
  });
};

export const GET_PAYABLE_FEES_STATISTICS = `${INVOICES_URL}/GET_PAYABLE_FEES_STATISTICS`;

export const useGetPayableFeesStatisticsQuery = (
  params: GetPayableFeesStatisticsParams,
  options?: RQUseQueryOptions<GetPayableFeesStatisticsResponse>,
) => {
  return useQuery<GetPayableFeesStatisticsResponse, ApiError>(
    [GET_STUDENT_PRODUCTS_QUERY, params],
    () => getPayableFeesStatistics(params),
    {
      ...options,
    },
  );
};
type GetPayableFeesParams = {
  school_ids: string;
  year_id: string;
  relation_ids?: string[]; // any school user relation id
  query?: string;
  company_ids?: string[];
  page_number?: number;
  page_size?: number;
};

type PayableFeePayer =
  | {
      type: PayerType.Company;
      data: Company;
    }
  | {
      type: PayerType.Default;
      data: DefaultPayer & { id: string };
    };

export type FeeItem = {
  product_id: string;
  product_type: ProductBillingType;
  name: string;
  // Not supported yet
  // item_total: number;
  // item_current: number;
  price: number;
  price_full: number;
  students: DependantStudent[];
  variant_id: string;
  variant_name: string;
  label: string;
  discount_amount: number;
  discount_percent: number;
  frequency_id: string;
};

export type PayableFee = {
  payer: PayableFeePayer;
  students: DependantStudent[];
  status: PayableFeeStatus;
  issue_date: string;
  due_date: string;
  total_paid: number | null; // number if status is partially_paid
  total_payment: number;
  items: FeeItem[];
  currency: Currencies;
  invoice_date: string;
  invoice_id: string;
  invoice_link_for_issuer: StringOrNull;
  invoice_number: StringOrNull;
};

const getPayableFees = async ({
  relation_ids,
  company_ids,
  ...rest
}: GetPayableFeesParams): Promise<PagedResponse<PayableFee>> => {
  const params = removeObjectUndefinedNullValues({
    relation_ids: relation_ids?.join(','),
    company_ids: company_ids?.join(','),
    ...rest,
  });
  return api.get(`${INVOICES_URL}/invoice/for-school`, { params });
};

export const GET_PAYABLE_FEES = `${INVOICES_URL}/GET_PAYABLE_FEES`;

export const useGetPayableFeesQuery = (
  initialParams: GetPayableFeesParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<PayableFee>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<PayableFee>, ApiError>(
    [GET_PAYABLE_FEES, params],
    ({ pageParam }) =>
      getPayableFees({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type SingleInvoiceAssignedProduct = {
  quantity: number;
  discount_percent?: number;
  price: number;
  variant_id: string;
  type_id: string;
  type_name: string;
  id: string;
  name: string;
  billing_connection: ProductType['billing_connection'];
};

export type SingleInvoiceStudent = SchoolRelation & WithName & WithAvatar;

export type SingleInvoicePayer =
  | (Company & { type: 'company' })
  | (Guardian & { type: 'guardian' });

export type SingleInvoice = {
  generation_date: string;
  invoice_date: string;
  due_date: string;
  assignments: Array<{
    relation: SingleInvoiceStudent;
    payer: SingleInvoicePayer;
    products: Array<SingleInvoiceAssignedProduct>;
  }>;
};

export type SingleInvoiceSave = {
  generation_date: string;
  invoice_date: string;
  due_date: string;
  assignments: Array<{
    relation_id: string;
    payer: {
      type: 'company' | 'guardian';
      id: string;
    };
    products: Array<Omit<SingleInvoiceAssignedProduct, 'price' | 'billing_connection'>>;
  }>;
};

type CreateSingleInvoiceParams = {
  school_id: string;
  single_invoice: SingleInvoiceSave;
};

type CreateSingleInvoiceResponse = { single_invoices: SingleInvoice[] };

export const createSingleInvoices = ({
  school_id,
  single_invoice,
}: CreateSingleInvoiceParams): Promise<CreateSingleInvoiceResponse> => {
  return api.post(`${INVOICES_URL}/single-invoices`, {
    school_id,
    single_invoice,
  });
};

export const useCreateSingleInvoicesMutation = (
  options?: RQUseMutationOptions<CreateSingleInvoiceResponse, CreateSingleInvoiceParams>,
): RQUseMutationResult<CreateSingleInvoiceResponse, CreateSingleInvoiceParams> => {
  return useMutation(createSingleInvoices, {
    ...options,
  });
};
