import { IconButton, Stack } from '@mui/material';
import { ConductTypeCreate, ConductTypeSelectOption, SelectOption } from '@schooly/api';
import { ConductConnotation, ConductTypeRepresentation } from '@schooly/api';
import { CrossIcon } from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';
import { v4 as uuidv4 } from 'uuid';

import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import {
  ModalContent,
  ModalMain,
  ModalSmall,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { SchoolConductTypeSelect } from './SchoolConductTypeSelect';

export const CONNOTATION_OPTIONS = $enum(ConductConnotation).map((value, key) => ({
  labelTextId: `conduct-connotation-${key}`,
  value,
  checkColor: key,
}));

export const TYPE_OPTIONS = $enum(ConductTypeRepresentation).map<
  SelectOption<ConductTypeRepresentation>
>((value, key) => ({
  labelTextId: `conduct-type-${key}`,
  value,
}));

export type ConductTypeForm = Omit<ConductTypeCreate, 'select_type_options'> & {
  id?: string;
  archived: boolean;
  select_type_options: (ConductTypeSelectOption & {
    id: string;
    saved: boolean;
    original_order: number;
  })[];
};

const defaultValues = {
  name: '',
  type: undefined,
  connotation: undefined,
  select_type_options: [],
  archived: false,
  order: 0,
};

type SchoolConductTypeModalContentProps = {
  type?: Partial<ConductTypeCreate> & { id?: string };
  types: (ConductTypeCreate & { id?: string })[];
  isNewItem: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  editConnotationDisabled?: boolean;
  onDelete?: () => void;
  onClose: () => void;
  onSubmit: (v: ConductTypeForm) => void;
};

export const SchoolConductTypeModalContent: FC<SchoolConductTypeModalContentProps> = ({
  type,
  types,
  isDeleting,
  isSaving,
  isNewItem,
  onClose,
  onDelete,
  onSubmit,
  editConnotationDisabled,
}) => {
  const { $t, formatMessage } = useIntl();

  const form = useForm<ConductTypeForm>({
    defaultValues: type
      ? {
          name: type.name,
          connotation: type.connotation,
          type: type.type,
          select_type_options: type?.select_type_options
            ?.map((option, index) => ({
              ...option,
              saved: true,
            }))
            .sort((a, b) => a.order - b.order),
        }
      : defaultValues,
    mode: 'onChange',
  });

  const conductType = form.watch('type');

  const handleSelectFieldTypeClose = useCallback(
    (v?: SelectOption<ConductTypeRepresentation>) => {
      if (v?.labelTextId === TYPE_OPTIONS[2].labelTextId) {
        form.setValue('select_type_options', [
          {
            id: uuidv4(),
            label: '',
            order: 0,
            original_order: 0,
            archived: false,
            saved: false,
          },
        ]);
      } else {
        form.resetField('select_type_options');
      }
    },
    [form],
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            active
            disabled={isSaving}
            title={
              <ControlModalHeaderInput
                control={form.control}
                name="name"
                rules={{
                  required: true,
                  validate: (value) => {
                    const isUsed = types.filter(
                      (ct) =>
                        ct.id !== type?.id &&
                        ct.name.toLowerCase() === value?.toString().toLowerCase(),
                    );

                    if (isUsed.length) {
                      return formatMessage({
                        id: 'school-sections-ConductSettings-Message-NameExists',
                      });
                    }

                    return true;
                  },
                }}
                placeholder={$t({ id: 'school-section-ConductSettings-ConductTypeName' })}
                autoFocus={isNewItem}
              />
            }
          >
            {!isSaving && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>

          <ModalMain>
            <ModalContent active>
              <Stack alignItems="flex-start" gap={2} sx={{ height: '100%', width: '100%' }}>
                <FormRadioGroup
                  options={CONNOTATION_OPTIONS}
                  name="connotation"
                  rules={{ required: true }}
                />

                <FormSelect2
                  disabled={editConnotationDisabled}
                  id="school-section-ConductSettings-ConductType"
                  name="type"
                  labelTextId="school-section-ConductSettings-ConductType"
                  options={TYPE_OPTIONS}
                  rules={{ required: true }}
                  onSelectOption={handleSelectFieldTypeClose}
                />

                {conductType === ConductTypeRepresentation.SELECT && (
                  <Stack width="100%">
                    <SchoolConductTypeSelect />
                  </Stack>
                )}
              </Stack>
            </ModalContent>
          </ModalMain>

          <ModalFooterWithActions
            saving={isSaving}
            deleting={isDeleting}
            disabled={isSaving || isDeleting}
            showDeleteButton={!!onDelete}
            onDeleteClick={onDelete}
            isNewItem={isNewItem}
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
