import { Comment } from '../base';
import { NumberOrNull, StringOrNull } from '../misc';
import { WithAvatar } from '../partials';
import { SchoolRelation } from '../relations';

export enum ConductConnotation {
  POSITIVE = 1,
  NEGATIVE = 2,
}

export enum ConductTypeRepresentation {
  TEXT = 1,
  NUMBER = 2,
  SELECT = 3,
}

export enum ConductVisibility {
  NOT_PUBLISHED = 1,
  PUBLISHED = 2,
}

export interface ConductSelectType {
  conduct_type_id: string;
  conduct_type_options: string[];
}

export interface ConductTypeUpdate {
  name?: string;
  connotation?: ConductConnotation;
  description?: StringOrNull;
  select_type_options?: ConductTypeSelectOption[];
}

export interface ConductTypeSelectOption {
  order: number;
  label: string;
  archived: boolean;
}

export interface ConductTypeCreate {
  name: string;
  type: ConductTypeRepresentation;
  connotation: ConductConnotation;
  order: number;
  description?: StringOrNull;
  select_type_options?: ConductTypeSelectOption[];
}

export interface ConductTypeHandle {
  conduct_type_id?: string;
  name: string;
  type: ConductTypeRepresentation;
  connotation: ConductConnotation;
  order: number;
  description?: StringOrNull;
  select_type_options?: ConductTypeSelectOption[];
}

export interface ConductType {
  id: string;
  type: ConductTypeRepresentation;
  name: string;
  description?: StringOrNull;
  connotation: ConductConnotation;
  order: number;
  select_type_options?: ConductTypeSelectOption[];
  archived: boolean;
}

export interface ConductEntry {
  id: string;
  date: string;
  title: StringOrNull;
  value: NumberOrNull;
  student: SchoolRelation & WithAvatar;
  conduct_type: ConductType;
  details: StringOrNull;
  comments: Comment[];
  staff: SchoolRelation & WithAvatar;
  visibility: ConductVisibility;
  // select_type_options?: ConductTypeSelectOption[];
}

export interface ConductEntryPatchBase {
  title?: StringOrNull;
  value?: StringOrNull;
  details?: string;
  visibility?: ConductVisibility;
  select_type_options?: ConductSelectType[];
}

export interface ConductEntryPatch extends ConductEntryPatchBase {
  comments?: Comment[]; // multiple comments for preview
}

export interface ConductEntryPatchRequest extends ConductEntryPatchBase {
  conduct_type_id: string;
  date: string;
}

export interface ConductStat {
  school_property_id: string | null;
  school_property_order: number;
  total: number | null;
}
