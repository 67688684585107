import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { ConductEntry, ConductVisibility, DATE_FORMAT_SHORT_FNS } from '@schooly/api';
import { DropdownIcon, EditIcon, TypographyWithOverflowHint } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ConductConnotationIndicator } from '../../../pages/Conduct/ConductGrid';
import { ConductVisibilityButton } from '../../../pages/Conduct/ConductVisibilityButton';
import { commentToDropdownCommentItem } from '../../../utils/convertComments';
import PersonCardBasic from '../../common/PersonCard/PersonCardBasic';
import { DropdownComments } from '../DropdownCommentsV2/DropdownComments';
import { CommentRowCell } from '../DropdownCommentsV2/DropdownCommentsWrappers/CommentRowCell';
import { GridRowDate, GridRowItem } from '../Grid/Grid';
import {
  ConductGridRow,
  DetailsExpandable,
  GridRowConnotation,
  GridRowExpand,
  GridRowName,
  GridRowPublished,
  GridRowValue,
  ListViewRowActions,
} from './ListViewConductRow.styled';

export interface ListViewConductRowProps {
  conduct: ConductEntry;
}

export const ListViewConductRow: FC<ListViewConductRowProps> = ({ conduct }) => {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(false);
  const comments = conduct.comments.map(commentToDropdownCommentItem);
  const isExpandable = !!conduct.details;

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const handleEditClick = useCallback(() => {
    navigate(`/conduct/${conduct.id}/edit`);
  }, [navigate, conduct.id]);

  return (
    <ConductGridRow>
      <GridRowItem noVerticalPadding>
        <GridRowDate>
          {format(newDateTimezoneOffset(conduct.date), DATE_FORMAT_SHORT_FNS)}
        </GridRowDate>
        <GridRowName>
          <PersonCardBasic user={conduct.student} userType="student" isListItem />
        </GridRowName>
        <GridRowPublished>
          {conduct.visibility === ConductVisibility.PUBLISHED && (
            <ConductVisibilityButton
              tooltipTitle={<FormattedMessage id="conduct-parents-visible" />}
              selected
              nowrap
            />
          )}
        </GridRowPublished>
        <GridRowConnotation>
          <Stack direction="row" alignItems="center" gap={1} whiteSpace="nowrap">
            <ConductConnotationIndicator connotation={conduct.conduct_type.connotation} />
            <TypographyWithOverflowHint>{conduct.conduct_type.name}</TypographyWithOverflowHint>
          </Stack>
        </GridRowConnotation>
        <GridRowValue>
          <TypographyWithOverflowHint pl={0.5}>
            {conduct.value || conduct.title}
          </TypographyWithOverflowHint>
        </GridRowValue>
        <GridRowExpand>
          <Icon
            className="icon-Expand"
            sx={{
              cursor: 'pointer',
              visibility: isExpandable ? 'visible' : 'hidden',
              transform: isExpanded ? 'rotate(180deg)' : undefined,
              color: 'common.grey',
            }}
            onClick={toggleExpand}
          >
            <DropdownIcon />
          </Icon>
        </GridRowExpand>
        <CommentRowCell>
          {(onToggle, cellRef) => (
            <Stack sx={{ visibility: comments.length ? 'visible' : 'hidden' }}>
              <DropdownComments
                canAdd={false}
                canEditOwn={false}
                comments={comments}
                getParentRef={() => cellRef}
                onToggle={onToggle}
                popoverMargin={2}
              />
            </Stack>
          )}
        </CommentRowCell>
        <ListViewRowActions>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </ListViewRowActions>
      </GridRowItem>
      {isExpandable && (
        <DetailsExpandable isExpanded={isExpanded}>
          <Typography>{conduct.details}</Typography>
        </DetailsExpandable>
      )}
    </ConductGridRow>
  );
};
