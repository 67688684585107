import { ConductSelectTypesSelectedValue } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC, PropsWithChildren, useRef, useState } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectSearchInput } from '../SelectSearchInput';
import { ConductTypeOptionsSelectContent } from './ConductTypeOptionsSelectContent';

type ConductTypeOptionsExpandedSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedValue: ConductSelectTypesSelectedValue[];
  onClear: () => void;
  onSelectOption: (conductTypeId: string, option: string) => void;
  onClose: () => void;
}>;

export const ConductTypeOptionsExpandedSelect: FC<ConductTypeOptionsExpandedSelectProps> = ({
  schoolId,
  selectedValue,
  onSelectOption,
  onClose,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');

  return (
    <ExpandedSelect
      hasSelectedValue={selectedValue.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={() => (
        <ConductTypeOptionsSelectContent
          schoolId={schoolId}
          query={query}
          selectedValue={selectedValue}
          onSelectOption={onSelectOption}
        />
      )}
    >
      {selectedValue.map((v) =>
        v.conduct_type_options.map((option) => {
          return (
            <TagSelect
              label={option}
              size="small"
              onDelete={() => onSelectOption(v.conduct_type_id, option)}
            />
          );
        }),
      )}

      <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
    </ExpandedSelect>
  );
};
