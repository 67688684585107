import {
  GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY,
  useCreateConductTypesMutation,
  useDeleteConductTypeMutation,
  useGetConductTypeQuery,
  useGetConductTypesForSchoolQuery,
  useUpdateConductTypeMutation,
} from '@schooly/api';
import { ConductConnotation, ConductTypeRepresentation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { Loading } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { $enum } from 'ts-enum-util';
import { v4 as uuidv4 } from 'uuid';

import { ModalSmall } from '../../../components/uikit-components/Modal/Modal.styled';
import { useConductCommon } from '../../../context/conduct/useConductCommon';
import { useRouter } from '../../../context/router/useRouter';
import { ConductTypeForm, SchoolConductTypeModalContent } from './SchoolConductTypeModalContent';

export const CONNOTATION_OPTIONS = $enum(ConductConnotation).map((value, key) => ({
  labelTextId: `conduct-connotation-${key}`,
  value,
  checkColor: key,
}));

export const TYPE_OPTIONS = $enum(ConductTypeRepresentation).map((value, key) => ({
  labelTextId: `conduct-type-${key}`,
  value,
}));

export const SchoolConductTypeModal: FC = () => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const { id: typeId = '' } = useParams<'id'>();
  const { showNotification, showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { goBack } = useRouter();

  // TODO: Remove outdated conduct context from the app
  const { fetchTypes } = useConductCommon();
  const queryClient = useQueryClient();
  const closeModal = goBack;

  const updateConductType = useUpdateConductTypeMutation();
  const createConductTypes = useCreateConductTypesMutation();
  const deleteConductType = useDeleteConductTypeMutation();

  const { data: types } = useGetConductTypesForSchoolQuery(schoolId, {
    enabled: !!schoolId,
    refetchOnMount: 'always',
  });

  const { data: type, isFetching } = useGetConductTypeQuery(typeId, {
    enabled: !!typeId,
    refetchOnMount: 'always',
  });

  const formattedType = useMemo(
    () => ({
      ...type,
      select_type_options: type?.select_type_options?.map((option, index) => ({
        ...option,
        id: uuidv4(),
        original_order: index,
      })),
    }),
    [type],
  );

  const handleSave = useCallback(
    async ({ name, description, connotation, type, select_type_options }: ConductTypeForm) => {
      if (!schoolId || !type || !types || !name || !connotation) return;

      const getNextOrderValue = () => Math.max(...types.map((t) => t.order), 0) + 1;

      const onSuccess = () => {
        showNotification({
          textId: typeId
            ? 'school-section-ConductSettings-ConductTypeUpdated'
            : 'school-section-ConductSettings-ConductTypeSaved',
          values: { typeName: name },
          type: 'success',
        });
        fetchTypes();
        queryClient.invalidateQueries([GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY]);
        closeModal();
      };

      const payload = {
        name,
        connotation,
        select_type_options: select_type_options
          .map((option, i) => ({
            label: option.label,
            order: i,
            original_order: option.original_order,
            archived: option.archived,
            id: option.id,
          }))
          .sort((a, b) => {
            return a.original_order - b.original_order;
          })
          .map((option) => ({
            label: option.label,
            order: option.order,
            archived: option.archived,
          })),
      };

      if (typeId) {
        updateConductType.mutate(
          {
            typeId,
            conductType: {
              ...payload,
              description,
            },
          },
          {
            onSuccess,
            onError: showError,
          },
        );
        return;
      }

      createConductTypes.mutate(
        {
          schoolId,
          conductTypes: [
            {
              ...payload,
              type,
              order: getNextOrderValue(),
            },
          ],
        },
        {
          onSuccess,
          onError: showError,
        },
      );
    },
    [
      schoolId,
      types,
      typeId,
      createConductTypes,
      showError,
      showNotification,
      fetchTypes,
      queryClient,
      closeModal,
      updateConductType,
    ],
  );

  const handleDelete = useCallback(async () => {
    if (!type) return;

    if (
      !(await getConfirmation({
        message: $t(
          { id: 'school-section-ConductSettings-ConductTypeRemoveConfirmAction' },
          { typeName: type.name },
        ),
      }))
    ) {
      return;
    }

    deleteConductType.mutate(typeId, {
      onError: showError,
      onSuccess: () => {
        showNotification({
          textId: $t(
            { id: 'school-section-ConductSettings-ConductTypeRemoved' },
            { typeName: type.name },
          ),
          type: 'success',
        });
        fetchTypes();
        closeModal();
        queryClient.invalidateQueries([GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY]);
      },
    });
  }, [
    type,
    getConfirmation,
    $t,
    deleteConductType,
    typeId,
    showError,
    showNotification,
    fetchTypes,
    closeModal,
    queryClient,
  ]);

  if (isFetching) {
    return (
      <ModalSmall open onClose={closeModal}>
        <Loading />
      </ModalSmall>
    );
  }

  return (
    <SchoolConductTypeModalContent
      type={formattedType}
      types={types ?? []}
      isNewItem={!typeId}
      isDeleting={deleteConductType.isLoading}
      onClose={closeModal}
      onDelete={typeId ? handleDelete : undefined}
      onSubmit={handleSave}
      isSaving={updateConductType.isLoading || createConductTypes.isLoading}
      editConnotationDisabled={!!typeId}
    />
  );
};
