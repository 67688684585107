import { Icon, Skeleton, SxProps, Tooltip, Typography } from '@mui/material';
import { AssignedProduct, Company, DefaultPayer, PayerType, PaymentFrequency } from '@schooly/api';
import {
  CompanyIcon,
  GridCell,
  GridContainer,
  GridRowCell,
  GridRowName,
  LockIcon,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  ProfileIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Table, TableCell } from '../../../../components/uikit-components/Table/Table';
import { getCurrencySymbol } from '../../../School/SchoolProducts/helpers';
import { AssignedProductPayer } from './AssignedProductPayer';

interface AssignedProductsTableProps {
  products: AssignedProduct[];
  payer?: DefaultPayer;
  company?: Company;
  frequencies?: PaymentFrequency[];
  headerTitle: string;
  sx?: SxProps;
  schoolId: string;
}

export const AssignedProductsTable: FC<AssignedProductsTableProps> = ({
  products,
  payer,
  company,
  frequencies,
  headerTitle,
  sx,
  schoolId,
}) => {
  const { formatMessage } = useIntl();

  const withPayer = Boolean(payer ?? company);

  const renderPayer = useCallback(
    (product: AssignedProduct) => {
      const isDefaultPayer = product.payer_type === PayerType.Default;
      const id = isDefaultPayer ? payer?.relation_id : company?.id;
      const name = isDefaultPayer && payer ? getUserFullName(payer) : company?.name;
      const telephone = isDefaultPayer ? payer?.telephone : company?.telephone;
      const email = isDefaultPayer ? payer?.email : company?.email;
      const contactName = isDefaultPayer ? undefined : company?.contact_name;
      const inviteStatus = isDefaultPayer ? payer?.invite_status : undefined;
      const icon = isDefaultPayer ? <ProfileIcon /> : <CompanyIcon />;

      if (!id) return null;

      return (
        <AssignedProductPayer
          id={id}
          name={name}
          telephone={telephone}
          email={email}
          contactName={contactName}
          inviteStatus={inviteStatus}
          icon={icon}
          schoolId={schoolId}
        />
      );
    },
    [company, payer, schoolId],
  );

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'Name',
        renderContent: () => (
          <GridCell py={1}>
            <Typography
              variant="h4"
              color="text.secondary"
              sx={{ '&.MuiTypography-root': { marginBottom: 0 } }}
            >
              {headerTitle}
            </Typography>
          </GridCell>
        ),
      },
      ...(withPayer
        ? [
            {
              id: 'Payer',
              renderContent: () => (
                <GridCell py={1}>{formatMessage({ id: 'profile-Payer' })}</GridCell>
              ),
            },
          ]
        : []),
      {
        id: 'Frequency',
        renderContent: () => (
          <GridCell py={1}>{formatMessage({ id: 'profile-Frequency' })}</GridCell>
        ),
      },
      {
        id: 'Price',
        renderContent: () => <GridCell py={1}>{formatMessage({ id: 'profile-Price' })}</GridCell>,
      },
      {
        id: 'Discount',
        renderContent: () => <GridCell py={1}>{formatMessage({ id: 'profile-Dis' })}</GridCell>,
      },
      {
        id: 'Amount',
        renderContent: () => <GridCell py={1}>{formatMessage({ id: 'profile-Amount' })}</GridCell>,
      },
    ],
    [formatMessage, headerTitle, withPayer],
  );

  const rows = useMemo(() => {
    if (!products) {
      return [];
    }
    return products.map((product) => {
      const frequency = frequencies?.find((f) => f.id === product.variant.frequency_id);
      const currencySymbol = getCurrencySymbol(product.variant.currency);

      const cellArray: TableCell[] = [
        {
          id: 'Name',
          renderContent: () => (
            <GridCell sx={{ maxWidth: 300 }} py={1}>
              <GridRowName sx={{ display: 'flex' }} gap={1}>
                {product.obligatory && <AssignedProductObligatoryIcon />}
                <TypographyWithOverflowHint
                  variant="h3"
                  component={Link}
                  to={`/settings/products/${product.id}`}
                  sx={{ '&:hover': { textDecoration: 'underline' } }}
                >
                  {product.variant.type_name} {product.name}
                </TypographyWithOverflowHint>
              </GridRowName>
            </GridCell>
          ),
        },
        ...(withPayer
          ? [
              {
                id: 'Payer',
                renderContent: () => (
                  <GridCell sx={{ width: 45 }} py={1}>
                    <GridRowCell>{renderPayer(product)}</GridRowCell>
                  </GridCell>
                ),
              },
            ]
          : []),
        {
          id: 'Frequency',
          renderContent: () => (
            <GridCell sx={{ width: 100 }} py={1}>
              <GridRowCell>
                {frequency ? (
                  <Typography>{formatMessage({ id: `frequencies-${frequency.type}` })}</Typography>
                ) : (
                  <Skeleton />
                )}
              </GridRowCell>
            </GridCell>
          ),
        },
        {
          id: 'Price',
          renderContent: () => (
            <GridCell sx={{ width: 120 }} py={1}>
              <GridRowCell>
                <Price
                  variant="body1"
                  price={product.variant.price}
                  currency={currencySymbol}
                  isOldPrice={!!product.discount_percent}
                />
              </GridRowCell>
            </GridCell>
          ),
        },
        {
          id: 'Discount',
          renderContent: () => (
            <GridCell sx={{ width: 60 }} py={1}>
              <GridRowCell>
                <Typography>
                  {product.discount_percent ? `${product.discount_percent}%` : '-'}
                </Typography>
              </GridRowCell>
            </GridCell>
          ),
        },
        {
          id: 'Amount',
          renderContent: () => (
            <GridCell sx={{ width: 120 }} py={1}>
              <GridRowCell>
                <Price
                  variant="body1"
                  price={product.discounted_price ?? product.variant.price}
                  currency={currencySymbol}
                />
              </GridRowCell>
            </GridCell>
          ),
        },
      ];

      return {
        id: product.id,
        cells: cellArray,
      };
    });
  }, [formatMessage, frequencies, products, renderPayer, withPayer]);

  return (
    <GridContainer
      sx={{
        '& .MuiTableBody-root .MuiTableRow-root': {
          ':hover': {
            [` .${PRICE_SUBTEXT_CLASS_NAME}`]: {
              color: 'common.grey2',
            },
            ' .MuiAvatar-root': {
              bgcolor: 'background.paper',
            },
          },
        },
        ...sx,
      }}
    >
      <Table columns={columns} rows={rows} withDefaultHover />
    </GridContainer>
  );
};

const AssignedProductObligatoryIcon = () => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      arrow={false}
      title={
        <Typography variant="caption">
          {formatMessage({ id: 'profile-RequiredProduct' })}
        </Typography>
      }
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            padding: theme.spacing(0, 0.5),
            borderRadius: theme.spacing(0.5),
            marginTop: theme.spacing(1),
          }),
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      }}
    >
      <Icon>
        <LockIcon />
      </Icon>
    </Tooltip>
  );
};
