import { Icon, Skeleton, Stack } from '@mui/material';
import {
  CONDUCT_ENTRIES_GROUP_BY_FILTER_KEYS,
  CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
  FilterKeys,
  FilterSection,
  GetConductEntriesQueryFilters,
  useGetConductTypesForSchoolQuery,
  useGetFiltersQuery,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SettingsIcon,
  SkeletonRows,
} from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { ConductContent, PAGE_SIZE, SKELETON_COLS } from './ConductContent';

export const ConductPage: FC = () => {
  const { $t } = useIntl();

  const { permissions, schoolId = '', relationId = '' } = useAuth();

  const navigate = useNavigate();
  const { data: types } = useGetConductTypesForSchoolQuery(schoolId, {
    enabled: !!schoolId,
    refetchOnMount: 'always',
  });

  const { data: propertiesData } = useGetSchoolPropertiesQuery(
    {
      schoolId: schoolId || '',
      userType: SchoolUserRole.Student,
    },
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );

  const { ageGroups } = useAgeGroups(
    {
      schoolId: schoolId || '',
      userType: SchoolUserRole.Student,
    },
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );

  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Conduct },
    { enabled: !!schoolId && !!relationId },
  );

  const canConfigureConduct =
    permissions.includes('conduct_manager') && permissions.includes('school_admin');

  const onConfigurationClick = useCallback(() => {
    navigate('/settings/conduct');
  }, [navigate]);

  const initialFiltersAndGroupBy = useMemo(() => {
    if (!propertiesData || isLoading) return;

    const initialFilters: GetConductEntriesQueryFilters = {};

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      let initialGroupBy: FilterKeys.Student | null = null;
      for (const key of CONDUCT_ENTRIES_GROUP_BY_FILTER_KEYS) {
        if (key === defaultFilter.filter.group_by) initialGroupBy = key;
      }
      return {
        initialFilters: pickOnlyParamsFromFilterKeys(
          CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
          defaultFilter.filter,
        ),
        initialGroupBy: initialGroupBy ?? null,
        initialConductTypeOptions: null,
      };
    }

    for (const property of propertiesData.school_properties) {
      if (!property.conduct_default) continue;

      const type = property.type;

      switch (type) {
        case SchoolPropertyType.House:
          initialFilters[type] = [...(initialFilters[type] || []), property.id];
          break;
      }
    }

    for (const ageGroup of ageGroups) {
      if (!ageGroup.conduct_default) continue;

      initialFilters[SchoolPropertyType.AgeGroup] = [
        ...(initialFilters[SchoolPropertyType.AgeGroup] || []),
        ageGroup.id,
      ];
    }

    return { initialFilters };
  }, [ageGroups, filters, isLoading, propertiesData]);

  const renderContent = () => {
    if (!types || !initialFiltersAndGroupBy) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="conduct-Title">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    const isConfigured = !!types?.length;

    if (!isConfigured) {
      return (
        <NoListItemsStub
          titleText={$t({ id: 'moduleNotConfigured' })}
          type="assessment"
          subTitleText={
            canConfigureConduct
              ? $t({ id: 'conduct-CreateGradeSystem' })
              : $t({ id: 'conduct-ContactAdministrator' })
          }
          buttonText={canConfigureConduct && $t({ id: 'school-section-ConductSettings' })}
          buttonIcon={canConfigureConduct && <SettingsIcon />}
          onButtonClick={onConfigurationClick}
        />
      );
    }

    return <ConductContent {...initialFiltersAndGroupBy} />;
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};
