import { ConductSelectTypesSelectedValue } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { ConductTypeOptionsSelectContent } from './ConductTypeOptionsSelectContent';

type ConductTypeOptionsSelectProps = {
  schoolId: string;
  selectedValue: ConductSelectTypesSelectedValue[];
  onSelectOption: (conductTypeId: string, option: string) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ConductTypeOptionsSelectMultiple: FC<ConductTypeOptionsSelectProps> = ({
  schoolId,
  selectedValue,
  onSelectOption,
  placeholder,
  ...dropdownProps
}) => {
  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedValue.length}
      renderContent={() => (
        <ConductTypeOptionsSelectContent
          schoolId={schoolId}
          selectedValue={selectedValue}
          onSelectOption={onSelectOption}
        />
      )}
    >
      {(opened) => (
        <>
          {selectedValue.map((v) =>
            v.conduct_type_options.map((option) => {
              return (
                <TagSelect
                  label={option}
                  size="small"
                  onDelete={opened ? () => onSelectOption(v.conduct_type_id, option) : undefined}
                />
              );
            }),
          )}
        </>
      )}
    </DropdownSelect>
  );
};
